import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import FormContainer from "src/components/Displays/FormContainer";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { ROUTES } from "src/consts/routes";
import { SESSION_KEYS, USER_PROFILE_DATA } from "src/consts/session";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { GlobalContextProps } from "src/components/GlobalContext/GlobalContext";
import AnnualConsumptionForm from "src/components/Forms/AnnualConsumptionForm";
import { annualGridSupplyFormSchema } from "src/schema/annualGridSupplyFormSchema";
import { useEffect } from "react";

export interface AnnualGridSupplyFormValues {
  personsInHousehold: number;
  yearlyGridSupplyKwh: string;
}

const AnnualGridSupply = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setAnimations } = useOutletContext<GlobalContextProps>();

  const [getSessionStorageItem, setSessionStorageItem] =
    useSessionStorage<USER_PROFILE_DATA>(SESSION_KEYS.USER_PROFILE_DATA);

  const form = useForm<AnnualGridSupplyFormValues>({
    defaultValues: {
      personsInHousehold: getSessionStorageItem()?.personsInHousehold || 2,
      yearlyGridSupplyKwh:
        getSessionStorageItem()?.yearlyGridSupplyKwh || "2500",
    },
    mode: "onSubmit",
    resolver: yupResolver(annualGridSupplyFormSchema),
  });

  const handleBackClick = () => {
    navigate(ROUTES.BATTERY_SETUP);
  };

  const onSubmit = (data: AnnualGridSupplyFormValues) => {
    const sessionData = getSessionStorageItem();
    const updatedSessionData = {
      ...sessionData,
      ...data,
    };

    setSessionStorageItem(updatedSessionData);
    setAnimations((prev) => ({ ...prev, continue: !prev.continue }));
    navigate(ROUTES.SMART_METER);
  };

  useEffect(() => {
    setAnimations((prev) => ({ ...prev, People: true }));
  }, [setAnimations]);

  return (
    <FormContainer
      title={t("page.annualGridSupply.formTitle")}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <Box sx={{ marginTop: 6, marginBottom: 3 }}>
        <AnnualConsumptionForm form={form} />
      </Box>
      <FormSubmitContainer onClick={handleBackClick} />
    </FormContainer>
  );
};

export default AnnualGridSupply;
