import { ThemeProvider, CssBaseline } from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import { THEMES, ThemeName } from "./theme";
import { useEffect, useMemo, useState } from "react";
import { Theme } from "@emotion/react";
import { ROUTES } from "src/consts/routes";
import { createContext } from "react";

interface ThemeUsageProps {
  children: React.ReactNode;
}

export const ThemeContext = createContext("");

const ThemeUsage = ({ children }: ThemeUsageProps) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [themeName, setThemeName] = useState<ThemeName>(
    (sessionStorage.getItem("theme") || "default") as ThemeName
  );

  const theme: Theme = useMemo(
    () => THEMES[themeName] || THEMES.default,
    [themeName]
  );

  useEffect(() => {
    if (sessionStorage.getItem("theme")) {
      return;
    }
    if (location.pathname === ROUTES.INTRODUCTION) {
      const item = (searchParams.get("theme") as ThemeName) || "default";
      sessionStorage.setItem("theme", item);
      setThemeName(item);
    }
  }, [location.pathname, searchParams]);

  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={themeName}>
        <CssBaseline />
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

export default ThemeUsage;
