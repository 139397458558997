import { Box, Stack } from "@mui/material";
import { CheckBadge } from "src/assets";
import Text from "../Displays/Text";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import {
  SESSION_KEYS,
  SITE_TECHNICAL_DATA,
  USER_PROFILE_DATA,
} from "src/consts/session";
import { PvSetupTypes } from "src/pages/PvSetup";
import { WallboxTypes } from "src/pages/Wallbox";
import { BatterySetupTypes } from "src/pages/BatterySetup";
import { SmartMeterTypes } from "src/pages/SmartMeter";
import { useTranslation } from "react-i18next";

const CalculatedSavingsForm = () => {
  const { t } = useTranslation();

  const [getSessionStorageItem] = useSessionStorage<SITE_TECHNICAL_DATA>(
    SESSION_KEYS.SITE_TECHNICAL_DATA
  );
  const [getUserSessionStorage] = useSessionStorage<USER_PROFILE_DATA>(
    SESSION_KEYS.USER_PROFILE_DATA
  );

  const pvSetup =
    getSessionStorageItem()?.pvSetup?.pvInstalled === PvSetupTypes.JA
      ? t("page.calculatedSavings.pvSetup.ja", {
          kWpSolar: getSessionStorageItem()?.pvSetup?.pvPowerMaxKwp,
          zipcode: getSessionStorageItem()?.pvSetup?.postalCode,
        })
      : t("page.calculatedSavings.pvSetup.nein");

  const wallbox =
    getSessionStorageItem()?.wallboxInstalled === WallboxTypes.JA
      ? t("page.calculatedSavings.wallbox.ja")
      : t("page.calculatedSavings.wallbox.nein");

  const batterySetup =
    getSessionStorageItem()?.batterySetup?.batteryInstalled ===
    BatterySetupTypes.JA
      ? t("page.calculatedSavings.batterySetup.ja", {
          kWhBatterie: getSessionStorageItem()?.batterySetup?.batteryCapacity,
        })
      : t("page.calculatedSavings.batterySetup.nein");

  const annualConsumption = t("page.calculatedSavings.annualConsumption", {
    kWhAnnualConsumption: getUserSessionStorage()?.yearlyGridSupplyKwh,
  });

  const smartMeter =
    getSessionStorageItem()?.smartMeterInstalled === SmartMeterTypes.JA
      ? t("page.calculatedSavings.smartMeter.ja")
      : t("page.calculatedSavings.smartMeter.nein");

  return (
    <Box sx={{ marginTop: 6, marginBottom: 3 }}>
      <Stack gap={2}>
        <Stack direction="row" gap={1}>
          <Stack>
            <CheckBadge />
          </Stack>
          <Text text={pvSetup} />
        </Stack>
        <Stack direction="row" gap={1}>
          <Stack>
            <CheckBadge />
          </Stack>
          <Text text={wallbox} />
        </Stack>
        <Stack direction="row" gap={1}>
          <Stack>
            <CheckBadge />
          </Stack>

          <Text text={batterySetup} />
        </Stack>
        <Stack direction="row" gap={1}>
          <Stack>
            <CheckBadge />
          </Stack>
          <Text text={annualConsumption} />
        </Stack>
        <Stack direction="row" gap={1}>
          <Stack>
            <CheckBadge />
          </Stack>
          <Text text={smartMeter} />
        </Stack>
      </Stack>
    </Box>
  );
};

export default CalculatedSavingsForm;
