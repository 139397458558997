import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BoldSubtitle from "src/components/Displays/BoldSubtitle";
import FormHeader from "src/components/Displays/FormHeader";
import PrimaryButton from "src/components/Shared/PrimaryButton";
import { SESSION_KEYS } from "src/consts/session";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { Tariff } from "src/models/clientsModel";

interface EconomyBoxProps {
  handleSubmit: () => void;
}

const EconomyBox = ({ handleSubmit }: EconomyBoxProps) => {
  const { t } = useTranslation();
  const [savingsYearly, setSavingsYearly] = useState("");

  const [getTarrifs] = useSessionStorage<Tariff[]>(SESSION_KEYS.TARIFFS);
  const tariffs = getTarrifs();

  useEffect(() => {
    if (tariffs && tariffs[0] && tariffs[0].savings) {
      setSavingsYearly(tariffs[0].savings.savingsYearly);
    }
  }, [tariffs]);

  return (
    <Stack
      gap={6}
      sx={(theme) => ({
        textAlign: "center",
        borderRadius: 2,
        px: 4,
        py: 2,
        backgroundColor: theme.palette.green[100],
      })}
    >
      <Stack
        sx={{
          textAlign: "center",
        }}
      >
        <BoldSubtitle
          sx={{
            fontWeight: 400,
            lineHeight: "27px",
          }}
          text={t("page.calculatedSavings.great")}
        />
        <FormHeader text={t("page.calculatedSavings.potentialSavings")} />
      </Stack>

      <Stack
        gap={2}
        direction={"row"}
        alignItems={"baseline"}
        justifyContent={"center"}
      >
        <BoldSubtitle
          sx={(theme) => ({
            color: theme.palette.green[50],
            fontSize: "49px",
            fontWeight: "450",
            lineHeight: "50px",
          })}
          text={`${savingsYearly} €`}
        />
        <BoldSubtitle
          sx={(theme) => ({
            color: theme.palette.green[50],
            fontSize: "20px",
          })}
          text={`/Jahr`}
        />
      </Stack>
      <PrimaryButton
        text={t("page.calculatedSavings.tarif")}
        onClick={handleSubmit}
        sx={{
          mb: 3,
          textTransform: "none",
        }}
      />
    </Stack>
  );
};

export default EconomyBox;
