import { defaultTheme, discoTheme, smaTheme } from "./themes";

export type ThemeName = "sma" | "disco" | "default";

export enum ThemeValues {
  SMA = "sma",
  default = "default",
}

export const THEMES = {
  sma: smaTheme,
  disco: discoTheme,
  default: defaultTheme,
};
