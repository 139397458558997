import { Box } from "@mui/material";
import FormContainer from "src/components/Displays/FormContainer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ContactInformationInputsForm from "src/components/Forms/ContactInformationInputsForm";
import { contactInformationInputsFormSchema } from "src/schema/contactInformationInputsFormSchema";
import { useTranslation } from "react-i18next";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { ROUTES } from "src/consts/routes";
import Page from "src/components/Layout/Page";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { SESSION_KEYS, USER_PROFILE_DATA } from "src/consts/session";
export interface ContactInformationFormValues {
  mobileNumber: string;
  email: string;
  confirmEmail: string;
}

const ContactInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formTitle = t("page.contactInformation.formTitle");
  const pageTitle = t("page.contactInformation.pageTitle");
  const pageSubTitle = t("page.contactInformation.pageSubTitle");

  const [getSessionStorageItem, setSessionStorageItem] =
    useSessionStorage<USER_PROFILE_DATA>(SESSION_KEYS.USER_PROFILE_DATA);

  const sessionData = getSessionStorageItem();

  const { control, handleSubmit } = useForm<ContactInformationFormValues>({
    defaultValues: {
      email: sessionData?.email,
      mobileNumber: sessionData?.mobileNumber,
      confirmEmail: sessionData?.confirmEmail,
    },
    mode: "onSubmit",
    resolver: yupResolver(contactInformationInputsFormSchema),
  });

  const onSubmit = (data: ContactInformationFormValues) => {
    const sessionData = getSessionStorageItem();
    const updatedSessionData: USER_PROFILE_DATA = {
      ...sessionData,
      mobileNumber: data.mobileNumber,
      email: data.email,
      confirmEmail: data.confirmEmail,
    };
    setSessionStorageItem(updatedSessionData);
    navigate(ROUTES.ADDRESS);
  };

  const handleBackClick = () => {
    navigate(ROUTES.YOUR_NAME);
  };

  return (
    <Page pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <FormContainer title={formTitle} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ marginTop: 6, marginBottom: 3 }}>
          <ContactInformationInputsForm control={control} />
        </Box>
        <FormSubmitContainer onClick={handleBackClick} />
      </FormContainer>
    </Page>
  );
};

export default ContactInformation;
