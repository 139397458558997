import { Stack, useTheme } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ProgressBarTitle from "src/components/Displays/ProgressBarTitle";
import { LichtBlickLogoSMA } from "src/assets";

interface TariffProviderModalContentProps {
  tariffProviderDisplayName: string;
}

const TariffProviderModalContent = ({
  tariffProviderDisplayName,
}: TariffProviderModalContentProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack gap={16}>
      <Stack gap={4}>
        <Stack direction={"row"} alignItems={"center"}>
          <ProgressBarTitle
            color={theme.palette.grey[500]}
            text={
              <Trans
                i18nKey={
                  t(
                    "page.tariff.tariffProviderInformationModal.header1"
                  ) as "page.tariff.tariffProviderInformationModal.header1"
                }
                t={t}
                components={{ bold: <strong /> }}
              />
            }
          />
          <LichtBlickLogoSMA />
        </Stack>

        <ProgressBarTitle
          color={theme.palette.grey[500]}
          text={t("page.tariff.tariffProviderInformationModal.text1")}
        />
      </Stack>

      <Stack gap={4}>
        <ProgressBarTitle
          color={theme.palette.grey[500]}
          text={
            <Trans
              i18nKey={
                t(
                  "page.tariff.tariffProviderInformationModal.header2"
                ) as "page.tariff.tariffProviderInformationModal.header2"
              }
              t={t}
              components={{ bold: <strong /> }}
            />
          }
        />

        <ProgressBarTitle
          color={theme.palette.grey[500]}
          text={t("page.tariff.tariffProviderInformationModal.text2")}
        />

        <ProgressBarTitle
          color={theme.palette.grey[500]}
          text={t("page.tariff.tariffProviderInformationModal.text3")}
        />
      </Stack>

      <Stack gap={4}>
        <ProgressBarTitle
          color={theme.palette.grey[500]}
          text={t("page.tariff.tariffProviderInformationModal.hotleine")}
        />

        <ProgressBarTitle
          color={theme.palette.grey[500]}
          text={t("page.tariff.tariffProviderInformationModal.email")}
        />
      </Stack>

      <Stack direction={"row"} gap={2}>
        <ProgressBarTitle
          color={theme.palette.grey[500]}
          text={
            <Trans
              i18nKey={
                t(
                  "page.tariff.tariffProviderInformationModal.impressum"
                ) as "page.tariff.tariffProviderInformationModal.impressum"
              }
              t={t}
              components={{
                linkTag: (
                  <Link
                    to="https://www.lichtblick.de/impressum/"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          }
        />

        <ProgressBarTitle
          color={theme.palette.grey[500]}
          text={
            <Trans
              i18nKey={
                t(
                  "page.tariff.tariffProviderInformationModal.website"
                ) as "page.tariff.tariffProviderInformationModal.website"
              }
              t={t}
              components={{
                linkTag: (
                  <Link
                    to="https://www.lichtblick.de"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          }
        />

        <ProgressBarTitle
          color={theme.palette.grey[500]}
          text={
            <Trans
              i18nKey={
                t(
                  "page.tariff.tariffProviderInformationModal.kontakt"
                ) as "page.tariff.tariffProviderInformationModal.kontakt"
              }
              t={t}
              components={{
                linkTag: (
                  <Link
                    to="https://www.lichtblick.de/kontakt/"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          }
        />
      </Stack>
    </Stack>
  );
};

export default TariffProviderModalContent;
