import { UseFormReturn } from "react-hook-form";
import RadioGroupInput from "src/components/Inputs/RadioGroupInput";
import RadioInput from "src/components/Inputs/RadioInput";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import {
  PaymentDetailsFormValues,
  PaymentDetailsTypes,
} from "src/pages/PaymentDetails";
import TextInput from "../Inputs/TextInput";
import Text from "../Displays/Text";
import CheckboxInput from "src/components/Inputs/CheckboxInput";
import GermanIbanInput from "../Inputs/GermanIBANInput";

interface ContractChangeInputsFormProps {
  form: UseFormReturn<PaymentDetailsFormValues, any, undefined>;
}

const PaymentDetailsForm = ({ form }: ContractChangeInputsFormProps) => {
  const { t } = useTranslation();
  return (
    <RadioGroupInput control={form.control} name="account">
      <RadioInput
        value={PaymentDetailsTypes.SEPA}
        label={t("page.paymentDetails.sepa.title")}
      />
      {form.watch("account") === PaymentDetailsTypes.SEPA && (
        <Stack gap={1}>
          <TextInput
            fullWidth
            control={form.control}
            name="cardHolder"
            labelText={t("page.paymentDetails.sepa.cardHolder")}
          />
          <GermanIbanInput
            fullWidth
            control={form.control}
            name="iban"
            labelText={t("page.paymentDetails.sepa.iban")}
          />
        </Stack>
      )}
      <Box
        sx={{
          pt: 3,
        }}
      >
        <RadioInput
          value={PaymentDetailsTypes.BANK}
          label={t("page.paymentDetails.bankTransfer.title")}
        />
      </Box>
      {form.watch("account") === PaymentDetailsTypes.BANK && (
        <Stack
          sx={{
            pt: 6,
            gap: 6,
          }}
        >
          <Text text={t("page.paymentDetails.bankTransfer.description")} />
        </Stack>
      )}
      {form.watch("account") === PaymentDetailsTypes.SEPA && (
        <Stack gap={6}>
          <Box
            sx={{
              pt: 6,
            }}
          >
            <Text text={t("page.paymentDetails.consent.title")} />
          </Box>
          <CheckboxInput
            control={form.control}
            checkboxLabelText={t("page.paymentDetails.consent.confirm")}
            name="consent"
          />
        </Stack>
      )}
    </RadioGroupInput>
  );
};

export default PaymentDetailsForm;
