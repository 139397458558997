import { Box, Stack, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "src/assets";
import Text from "src/components/Displays/Text";
import { ROUTES } from "src/consts/routes";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { SESSION_KEYS } from "src/consts/session";
import { Tariff } from "src/models/clientsModel";
import { useTranslation } from "react-i18next";
import { useToggle } from "src/hooks/useToggle";
import TarifSection from "./components/TarifSection";
import PrimaryButton from "src/components/Shared/PrimaryButton";

const Tarif = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [isOverlayOpen, toggleIsOverlayOpen] = useToggle(false);
  const [expanded, setExpanded] = useState<string | boolean>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const [getSessionStorageItem] = useSessionStorage<Tariff[]>(
    SESSION_KEYS.TARIFFS
  );

  const { tariffsData } = useMemo(() => {
    const tariffsData = getSessionStorageItem();
    return {
      tariffsData: tariffsData,
    };
  }, [getSessionStorageItem]);

  const tariffDisplayName =
    (tariffsData && tariffsData[0].tariffDisplayName) ?? "";

  return (
    <Stack gap={8}>
      <TarifSection
        expanded={expanded}
        tariffsData={tariffsData}
        handleChange={handleChange}
        isOverlayOpen={isOverlayOpen}
        toggleIsOverlayOpen={toggleIsOverlayOpen}
      />
      <Stack gap={2}>
        <PrimaryButton
          text={t("page.tariff.order", {
            name: tariffDisplayName,
          })}
          onClick={() => {
            navigate(ROUTES.YOUR_NAME);
          }}
          sx={(theme) => ({
            bgcolor: theme.palette.secondaryButton.background,
            color: theme.palette.secondaryButton.text,
            "&:hover": {
              backgroundColor: theme.palette.secondaryButton.background,
            },
            textTransform: "none",
          })}
        />
        <Stack
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(ROUTES.PV_SETUP);
          }}
          direction="row"
          gap={1}
          justifyContent="center"
        >
          <Text
            sx={(theme) => ({
              color: theme.palette.outlineButton.text,
              justifyContent: "center",
            })}
            text={t("page.tariff.settings")}
          />
          <Box>
            <ArrowRight color={theme.palette.outlineButton.text} />
          </Box>
        </Stack>
        <Stack
          sx={{ cursor: "pointer" }}
          direction="row"
          gap={1}
          justifyContent="center"
          onClick={() => {
            navigate(ROUTES.CALCULATED_SAVINGS);
          }}
        >
          <Text
            sx={(theme) => ({
              color: theme.palette.outlineButton.text,
            })}
            text={t("page.tariff.details")}
          />
          <Box>
            <ArrowRight color={theme.palette.outlineButton.text} />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Tarif;
