import { Stack, useTheme } from "@mui/material";

interface TabActiveProps {
  children?: React.ReactNode;
  isActive: boolean;
  toggleActive: () => void;
}

const TabActive = ({ children, isActive, toggleActive }: TabActiveProps) => {
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      gap={"1px"}
      width="100%"
      onClick={toggleActive}
      sx={{
        py: 2,
        display: "inline",
        cursor: "pointer",
        px: 6,
        textAlign: "center",
        borderRadius: "32px",
        backgroundColor: isActive
          ? theme.palette.tabActive.background
          : theme.palette.tabInactive.background,
      }}
    >
      {children}
    </Stack>
  );
};

export default TabActive;
