import { TariffSavings, EnergyProviders } from "src/models/clientsModel";
import { numberFormat } from "src/helpers/numberFormat";

export interface TariffSavingsMapped {
  savings: {
    savingsYearly: string;
    savingsMonthly: string;
    savingsSign: string;

    revenueFeedInYearly: string;
    revenueFeedInMonthly: string;
    revenueFeedInSign: string;

    costGridSupplyYearly: string;
    costGridSupplyMonthly: string;
    costGridSupplySign: string;

    costEnergyManagementYearly: string;
    costEnergyManagementMonthly: string;
    costEnergyManagementSign: string;

    costSmartMeterYearly: string;
    costSmartMeterMonthly: string;
    costSmartMeterSign: string;
  };
  tariffDisplayName: string;
  tariffLogicalId: string;
  tariffProviderDisplayName: string;
  tariffProviderLogicalId: string;
  fixTariff: {
    tariffDisplayName: string;
    workingPricePerKwh: string;
    basePriceMonthly: string;
    monthlyCosts: string;
    yearlyCosts: string;
  };
  flexTariff: {
    tariffDisplayName: string;
    workingPricePerKwh: string;
    basePriceMonthly: string;
    supplyPriceFixedElementPerKwh: string;
    supplyPriceFlexElementPerKwh: string;
  };
}

export const tariffSavingsMapped = (
  data: TariffSavings
): TariffSavingsMapped[] => {
  const updatedTariffs = data.tariffs.map((tariff) => {
    return {
      ...tariff,
      savings: {
        savingsYearly: numberFormat(tariff.savings?.savingsYearly ?? ""),
        savingsMonthly: numberFormat(tariff.savings?.savingsMonthly ?? ""),
        savingsSign: tariff.savings?.savingsSign ?? "",

        revenueFeedInYearly: numberFormat(
          tariff.savings?.revenueFeedInYearly ?? ""
        ),
        revenueFeedInMonthly: numberFormat(
          tariff.savings?.savingsMonthly ?? ""
        ),
        revenueFeedInSign: tariff.savings?.revenueFeedInSign ?? "",

        costGridSupplyYearly: numberFormat(
          tariff.savings?.costGridSupplyYearly ?? ""
        ),
        costGridSupplyMonthly: numberFormat(
          tariff.savings?.costGridSupplyMonthly ?? ""
        ),
        costGridSupplySign: tariff.savings?.costGridSupplySign ?? "",

        costEnergyManagementYearly: numberFormat(
          tariff.savings?.costEnergyManagementYearly ?? ""
        ),
        costEnergyManagementMonthly: numberFormat(
          tariff.savings?.costEnergyManagementMonthly ?? ""
        ),
        costEnergyManagementSign:
          tariff.savings?.costEnergyManagementSign ?? "",

        costSmartMeterYearly: numberFormat(
          tariff.savings?.costSmartMeterYearly ?? ""
        ),
        costSmartMeterMonthly: numberFormat(
          tariff.savings?.costSmartMeterMonthly ?? ""
        ),
        costSmartMeterSign: tariff.savings?.costSmartMeterSign ?? "",
      },
      fixTariff: {
        tariffDisplayName: tariff.fixTariff?.tariffDisplayName ?? "",
        workingPricePerKwh: numberFormat(
          tariff.fixTariff?.workingPricePerKwh ?? ""
        ),
        basePriceMonthly: numberFormat(
          tariff.fixTariff?.basePriceMonthly ?? ""
        ),
        monthlyCosts: numberFormat(tariff.fixTariff?.monthlyCosts ?? ""),
        yearlyCosts: numberFormat(tariff.fixTariff?.yearlyCosts ?? ""),
      },
      flexTariff: {
        tariffDisplayName: tariff.flexTariff?.tariffDisplayName ?? "",
        workingPricePerKwh: numberFormat(
          tariff.flexTariff?.workingPricePerKwh ?? ""
        ),
        basePriceMonthly: numberFormat(
          tariff.flexTariff?.basePriceMonthly ?? ""
        ),
        supplyPriceFixedElementPerKwh: numberFormat(
          tariff.flexTariff?.supplyPriceFixedElementPerKwh ?? ""
        ),
        supplyPriceFlexElementPerKwh: numberFormat(
          tariff.flexTariff?.supplyPriceFlexElementPerKwh ?? ""
        ),
      },
    };
  });
  return updatedTariffs;
};

export const energyProvidersMapped = (
  allEnergyProviders: EnergyProviders
): string[] => {
  const providersMapped: string[] = allEnergyProviders.energyProviders.map(
    (provider) => provider.displayName
  );
  return providersMapped;
};
