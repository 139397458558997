import { Box, Card, Stack, useTheme } from "@mui/material";
import { t } from "i18next";
import FormTitle from "src/components/Displays/FormTitle";
import PageTitle from "src/components/Displays/PageTitle";
import ElectricityCost from "./ElectricityCost";
import EnergyManagement from "./EnergyManagement";
import IntelligentMeasuer from "./IntelligentMeasure";
import ModalContent from "./ModalContent";
import Modal from "src/components/Modal/Modal";
import { Tariff } from "src/models/clientsModel";

interface TarifSectionProps {
  children?: React.ReactNode;
  expanded: string | boolean;
  handleChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  isOverlayOpen: boolean;
  toggleIsOverlayOpen: () => void;
  tariffsData?: Tariff[];
}

const TarifSection = ({
  children,
  expanded,
  tariffsData,
  handleChange,
  isOverlayOpen,
  toggleIsOverlayOpen,
}: TarifSectionProps) => {
  const theme = useTheme();
  return (
    <Stack>
      <Modal
        title={
          <PageTitle
            color={theme.palette.grey[500]}
            text={t("page.tariff.modal.title")}
          />
        }
        isOpen={isOverlayOpen}
        onClose={toggleIsOverlayOpen}
        onSubmit={toggleIsOverlayOpen}
      >
        <ModalContent />
      </Modal>

      <Card
        elevation={0}
        sx={(theme) => ({
          borderRadius: 2,
          background: theme.palette.form.background,
          border: "1px solid lightgray",

          p: 3,
        })}
      >
        <Box>
          <FormTitle text={t("page.tariff.title")} />
        </Box>

        <Stack>
          <ElectricityCost
            expanded={expanded}
            tariffsData={tariffsData}
            handleChange={handleChange}
          />
          <EnergyManagement
            expanded={expanded}
            tariffsData={tariffsData}
            handleChange={handleChange}
          />
          <IntelligentMeasuer
            expanded={expanded}
            tariffsData={tariffsData}
            handleChange={handleChange}
          />
        </Stack>

        {children}
      </Card>
    </Stack>
  );
};

export default TarifSection;
