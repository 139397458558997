import Text from "../Displays/Text";
import { useTranslation } from "react-i18next";
import PageSubTitle from "../Displays/PageSubTitle";
import { Link, Stack, useTheme } from "@mui/material";
import BrandItemThemeChanger from "../BrandIconThemeChanger/BrandIconThemeChanger";
import { useContext } from "react";
import { ThemeContext } from "../ThemeUsage/ThemeUsage";
import { ThemeValues } from "../ThemeUsage/theme";

interface FooterLinksProps {
  isIntroductionPage?: boolean;
}

const FooterLinks = ({ isIntroductionPage = false }: FooterLinksProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const themeName = useContext(ThemeContext);

  return (
    <Stack
      sx={{
        pt: 8,
        pb: 12,
        backgroundColor: theme.palette.app.primary,
      }}
      color={theme.palette.app.primary}
      alignItems="center"
    >
      <Stack
        sx={{
          width: "100%",
          px: 4,
          flexDirection: isIntroductionPage
            ? { xs: "column", sm: "row" }
            : { xs: "column" },
          justifyContent: isIntroductionPage
            ? { sm: "space-between" }
            : undefined,
          alignItems: isIntroductionPage ? { sm: "center" } : undefined,
          maxWidth: "880px",
        }}
        gap={themeName === ThemeValues.SMA ? 0 : 6}
      >
        {}
        <Stack direction="row" alignItems="center" gap={2}>
          {themeName !== ThemeValues.SMA && (
            <>
              <Text
                text={t("footer.poweredBy")}
                fontWeight={700}
                color={theme.palette.mainFont.primary}
              />
              <BrandItemThemeChanger color={theme.palette.app.contrast} />
            </>
          )}
        </Stack>

        <Stack direction="row" flexWrap={"wrap"} gap={3} mb={2}>
          <Link
            sx={(theme) => ({
              textDecoration: "none",
            })}
            href="https://ison.energy/en/legal-notice/"
            target="_blank"
          >
            <PageSubTitle
              color={theme.palette.mainFont.primary}
              text={t("footer.legalNotice")}
            />
          </Link>
          <Link
            sx={(theme) => ({
              textDecoration: "none",
            })}
            href="https://ison.energy/en/privacy/"
            target="_blank"
          >
            <PageSubTitle
              color={theme.palette.mainFont.primary}
              text={t("footer.privacy")}
            />
          </Link>
          <Link
            sx={(theme) => ({
              textDecoration: "none",
              fontSize: "14px",
            })}
            href="https://ison.energy/#contact"
            target="_blank"
          >
            <PageSubTitle
              color={theme.palette.mainFont.primary}
              text={t("footer.contact")}
            />
          </Link>
          <Link
            sx={(theme) => ({
              textDecoration: "none",
            })}
            href="https://www.lichtblick.de/vertrag-kuendigen/"
            target="_blank"
          >
            <PageSubTitle
              color={theme.palette.mainFont.primary}
              text={t("footer.terminateContract")}
            />
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FooterLinks;
