import { styled, CircularProgress } from "@mui/material";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

interface SubmitButtonProps extends LoadingButtonProps {
  isLoading?: boolean;
  buttonText?: string;
}

const SubmitButton = ({
  isLoading,
  buttonText = "Weiter",
  ...props
}: SubmitButtonProps) => {
  const SubmitButtonStyles = styled(LoadingButton)(({ theme }) => ({
    color: theme.palette.primaryButton.text,
    backgroundColor: theme.palette.primaryButton.background,
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "22px",
    borderRadius: "32px",
    "&:hover": {
      backgroundColor: theme.palette.primaryButton.background,
    },
    padding: "12px 24px 12px 24px",
    textTransform: "capitalize",
  }));
  return (
    <SubmitButtonStyles
      {...props}
      disableRipple
      loading={isLoading}
      loadingIndicator={<CircularProgress color="primary" size={16} />}
    >
      {buttonText}
    </SubmitButtonStyles>
  );
};

export default SubmitButton;
