import { Box, Stack } from "@mui/material";
import BackButton from "./BackButton";
import SubmitButton from "./SubmitButton";
import { SxProps, Theme } from "@mui/material";

interface FormSubmitContainerProps {
  sx?: SxProps<Theme>;
  onClick: () => void;
  isLoading?: boolean;
  submitButtonText?: string;
}

const FormSubmitContainer = ({
  onClick,
  isLoading,
  submitButtonText,
  sx = { textTransform: "none" },
}: FormSubmitContainerProps) => {
  return (
    <>
      <Box height={16} />
      <Stack direction={"row"} spacing={2}>
        <BackButton variant="dark" onClick={onClick} />
        <SubmitButton
          sx={sx}
          fullWidth
          type="submit"
          isLoading={isLoading}
          buttonText={submitButtonText}
        />
      </Stack>
    </>
  );
};

export default FormSubmitContainer;
