import { LichtBlickLogo, LichtBlickLogoSMA } from "src/assets";
import { ThemeValues } from "../ThemeUsage/theme";
import { ThemeContext } from "../ThemeUsage/ThemeUsage";
import { useContext } from "react";

const TariffProviderIcon = ({
  tariffProviderDisplayName,
}: {
  tariffProviderDisplayName: string;
}) => {
  const themeName = useContext(ThemeContext);
  const getLogo = () => {
    switch (themeName) {
      case ThemeValues.default:
        return <LichtBlickLogo />;
      case ThemeValues.SMA:
        return <LichtBlickLogoSMA />;
      default:
        return <></>;
    }
  };
  return <>{getLogo()}</>;
};

export default TariffProviderIcon;
