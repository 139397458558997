import {
  Box,
  IconButton,
  Modal as MuiModal,
  Stack,
  useTheme,
} from "@mui/material";
import { ReactNode } from "react";
import { Close } from "src/assets";
import PrimaryButton from "../Shared/PrimaryButton";
import { useTranslation } from "react-i18next";
import ProgressBarTitle from "../Displays/ProgressBarTitle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  justifyContent: "space-between",
};

interface Props {
  children?: ReactNode;
  title?: ReactNode;
  subTitle?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const Modal = ({
  children,
  onClose,
  title,
  subTitle,
  isOpen,
  onSubmit,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div>
      <MuiModal
        disableAutoFocus
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          sx={{
            ...style,
            width: "100%",
            maxWidth: "706px",
            borderRadius: { xs: 0, sm: "8px" },
            height: {
              xs: "100%",
              sm: "714px",
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              p: "16px",
            }}
          >
            <Stack gap={2}>
              {title}

              {subTitle && (
                <ProgressBarTitle
                  color={theme.palette.grey[500]}
                  text={subTitle}
                />
              )}
            </Stack>

            <Box>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
          </Stack>

          <Stack
            sx={{
              overflow: "auto",
              flex: 1,
              py: "24px",
              px: "16px",
            }}
          >
            {children}
          </Stack>

          <Stack
            sx={{
              p: 6,
            }}
          >
            <PrimaryButton
              text={t("confirm")}
              onClick={onSubmit}
              sx={{ textTransform: "none" }}
            />
          </Stack>
        </Stack>
      </MuiModal>
    </div>
  );
};

export default Modal;
