import { ROUTES } from "./routes";

export const TARIFF_PATHS = [
  ROUTES.PV_SETUP,
  ROUTES.WALLBOX,
  ROUTES.BATTERY_SETUP,
  ROUTES.ANNUAL_GRID_SUPPLY,
  ROUTES.SMART_METER,
  ROUTES.CALCULATED_SAVINGS,

  ROUTES.TARIF,
];

export const ORDER_PATHS = [
  ROUTES.YOUR_NAME,
  ROUTES.CONTACT_INFORMATION,
  ROUTES.ADDRESS,
  ROUTES.TECHNICAL_DETAILS,

  ROUTES.CONTRACT_CHANGE,
  ROUTES.CURRENT_SUPPLIER,
  ROUTES.DATE_OF_CONTRACT_START,

  ROUTES.PAYMENT_DETAILS,
  ROUTES.CONTRACT_SUMMARY,

  ROUTES.CONCLUSION,
];

export const ERROR_PATHS = [ROUTES.ERROR];

export const TARIFF_AND_ORDER_PATHS = [...TARIFF_PATHS, ...ORDER_PATHS];
