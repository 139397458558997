import { Stack } from "@mui/material";
import PageTitle from "../Displays/PageTitle";
import TariffProviderIcon from "../TarrifProviderIcon/TariffProbiderIcon";
import { useContext } from "react";
import { ThemeContext } from "../ThemeUsage/ThemeUsage";
import { ThemeValues } from "../ThemeUsage/theme";
import { Info, InfoWhite } from "src/assets";
import { useToggle } from "src/hooks/useToggle";
import Modal from "../Modal/Modal";
import TariffProviderModalContent from "../OrderTariffProgressBar/TariffProviderModalContent";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { Tariff } from "src/models/clientsModel";
import { SESSION_KEYS } from "src/consts/session";

interface CompanyDetailsProps {
  tariffDisplayName: string;
  tariffProviderDisplayName: string;
  by: string;
}

const CompanyDetails = ({
  tariffDisplayName,
  tariffProviderDisplayName,
  by,
}: CompanyDetailsProps) => {
  const [isOverlayOpen, toggleIsOverlayOpen] = useToggle(false);
  const [getTarrifs] = useSessionStorage<Tariff[]>(SESSION_KEYS.TARIFFS);

  const themeName = useContext(ThemeContext);
  const getLogo = () => {
    switch (themeName) {
      case ThemeValues.default:
        return <InfoWhite />;
      case ThemeValues.SMA:
        return <Info />;
      default:
        return <></>;
    }
  };

  return (
    <Stack>
      <PageTitle text={tariffDisplayName} />
      <Stack direction="row">
        <PageTitle text={by} />
        <Stack direction="row" alignItems={"center"}>
          <TariffProviderIcon
            tariffProviderDisplayName={tariffProviderDisplayName}
          />

          <Stack
            sx={{
              cursor: "pointer",
            }}
            onClick={toggleIsOverlayOpen}
          >
            {getLogo()}
          </Stack>
        </Stack>
      </Stack>

      <Modal
        isOpen={isOverlayOpen}
        onClose={toggleIsOverlayOpen}
        onSubmit={toggleIsOverlayOpen}
      >
        <TariffProviderModalContent
          tariffProviderDisplayName={
            getTarrifs()?.[0]?.tariffProviderDisplayName ?? ""
          }
        />
      </Modal>
    </Stack>
  );
};

export default CompanyDetails;
