import { useSessionStorage } from "src/hooks/useSessionStorage";
import {
  SESSION_KEYS,
  SITE_TECHNICAL_DATA,
  USER_PROFILE_DATA,
} from "src/consts/session";
import { numberFormat } from "src/helpers/numberFormat";
import { Tariff } from "src/models/clientsModel";
import { dateFormatForPayload } from "src/helpers/dateFormat";

const usePayloadMapped = () => {
  const [getSiteTechnicalData] = useSessionStorage<SITE_TECHNICAL_DATA>(
    SESSION_KEYS.SITE_TECHNICAL_DATA
  );
  const [getUserProfileData] = useSessionStorage<USER_PROFILE_DATA>(
    SESSION_KEYS.USER_PROFILE_DATA
  );
  const [getTarrifs] = useSessionStorage<Tariff[]>(SESSION_KEYS.TARIFFS);

  const siteTechnicalData = getSiteTechnicalData();
  const userProfileData = getUserProfileData();
  const tariffsData = getTarrifs();

  const pvInstalled = siteTechnicalData?.pvSetup?.pvInstalled
    ? JSON.parse(siteTechnicalData.pvSetup.pvInstalled)
    : false;

  const pvPowerMaxKwp =
    pvInstalled === true
      ? siteTechnicalData?.pvSetup?.pvPowerMaxKwp
        ? +numberFormat(siteTechnicalData.pvSetup.pvPowerMaxKwp)
        : null
      : null;

  const pvZipCode = siteTechnicalData?.pvSetup?.postalCode
    ? siteTechnicalData.pvSetup.postalCode
    : null;

  const wallboxInstalled = siteTechnicalData?.wallboxInstalled
    ? JSON.parse(siteTechnicalData.wallboxInstalled)
    : false;

  const batteryInstalled = siteTechnicalData?.batterySetup?.batteryInstalled
    ? JSON.parse(siteTechnicalData.batterySetup.batteryInstalled)
    : false;

  const batterySizeKwh =
    batteryInstalled === true
      ? siteTechnicalData?.batterySetup?.batteryCapacity
        ? +numberFormat(siteTechnicalData?.batterySetup?.batteryCapacity)
        : null
      : null;

  const yearlyGridSupplyKwh = userProfileData?.yearlyGridSupplyKwh
    ? getBatterySizeKWh(userProfileData?.yearlyGridSupplyKwh)
    : 0;

  const smartMeterInstalled = siteTechnicalData?.smartMeterInstalled
    ? JSON.parse(siteTechnicalData.smartMeterInstalled)
    : false;

  const firstname = userProfileData?.firstName ?? "";
  const lastname = userProfileData?.lastName ?? "";
  const country = userProfileData?.country ?? "";
  const zipcode = userProfileData?.zipCode ?? "";
  const city = userProfileData?.city ?? "";
  const street = userProfileData?.street ?? "";
  const streetNumber = userProfileData?.houseNumber ?? "";
  const emailAddress = userProfileData?.email ?? "";
  const mobilePhone = userProfileData?.mobileNumber ?? "";
  const invoiceStreet = userProfileData?.streetDeliveryAddress ?? "";
  const invoiceStreetNumber =
    userProfileData?.streetNumberDeliveryAddress ?? "";
  const invoiceZipcode = userProfileData?.zipCodeDeliveryAddress ?? "";
  const invoiceCity = userProfileData?.cityDeliveryAddress ?? "";

  const utilityToCancelExistingContract = siteTechnicalData?.cancelContractType
    ? siteTechnicalData.cancelContractType === "auto"
      ? true
      : false
    : false;

  const energyProviderUuid = siteTechnicalData?.energyProviderUuid ?? "";

  const newContractStartDate = dateFormatForPayload(
    siteTechnicalData?.contractStartDate ?? ""
  );

  const paymentMethod = siteTechnicalData?.paymentDetails?.account
    ? siteTechnicalData.paymentDetails.account === "SEPA"
      ? "SEPA"
      : "DIRECT_DEBIT"
    : "";

  const bankAccountHolder = siteTechnicalData?.paymentDetails?.account
    ? siteTechnicalData.paymentDetails.account === "SEPA"
      ? siteTechnicalData?.paymentDetails?.cardHolder ?? ""
      : null
    : null;

  const iban = siteTechnicalData?.paymentDetails?.account
    ? siteTechnicalData.paymentDetails.account === "SEPA"
      ? siteTechnicalData?.paymentDetails?.iban ?? ""
      : null
    : null;

  const agbSmartMeterAccepted = true;
  const agbEnergyProviderAccepted = true;
  const tariffLogicalId = tariffsData?.[0].tariffLogicalId ?? "";

  return {
    siteTechnicalData: {
      pvInstalled,
      pvPowerMaxKwp,
      pvZipCode,
      wallboxInstalled,
      batteryInstalled,
      batterySizeKwh,
      yearlyGridSupplyKwh,
      smartMeterInstalled,
    },

    userProfileData: {
      firstname,
      lastname,
      country,
      zipcode,
      city,
      street,
      streetNumber,
      emailAddress,
      mobilePhone,
      invoiceStreet,
      invoiceStreetNumber,
      invoiceZipcode,
      invoiceCity,
    },

    contractDetailData: {
      utilityToCancelExistingContract,
      newContractStartDate,
      energyProviderUuid,
      paymentMethod,
      iban,
      bankAccountHolder,
      agbSmartMeterAccepted,
      agbEnergyProviderAccepted,
      tariffLogicalId,
    },
  };
};

const getBatterySizeKWh = (value: string) => {
  const updatedValue = numberFormat(value);
  const updatedValueWihtoutComma = updatedValue.replace(",", "");
  return +updatedValueWihtoutComma;
};

export default usePayloadMapped;
