import { Control } from "react-hook-form";
import { ContactInformationFormValues } from "src/pages/ContactInformation";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextInput from "src/components/Inputs/TextInput";
import InputWrapper from "src/components/Inputs/InputWrapper";

interface ContactInformationInputsFormProps {
  control: Control<ContactInformationFormValues>;
}

const ContactInformationInputsForm = ({
  control,
}: ContactInformationInputsFormProps) => {
  const { t } = useTranslation();
  const mobileNumberLabel = t(
    "page.contactInformation.formFields.mobileNumber.label"
  );
  const emailLabel = t("page.contactInformation.formFields.email.label");
  const confirmEmailLabel = t(
    "page.contactInformation.formFields.confirmEmail.label"
  );

  return (
    <Stack sx={{ gap: 5 }}>
      <InputWrapper>
        <TextInput
          fullWidth
          control={control}
          name="mobileNumber"
          labelText={mobileNumberLabel}
        />
      </InputWrapper>

      <InputWrapper>
        <TextInput
          fullWidth
          control={control}
          name="email"
          labelText={emailLabel}
        />
      </InputWrapper>

      <InputWrapper>
        <TextInput
          fullWidth
          control={control}
          name="confirmEmail"
          labelText={confirmEmailLabel}
        />
      </InputWrapper>
    </Stack>
  );
};

export default ContactInformationInputsForm;
