import { UseFormReturn } from "react-hook-form";
import RadioGroupInput from "src/components/Inputs/RadioGroupInput";
import RadioInput from "src/components/Inputs/RadioInput";
import { useTranslation } from "react-i18next";
import { PvSetupFormValues, PvSetupTypes } from "src/pages/PvSetup";
import { Stack } from "@mui/material";
import TextInput from "../Inputs/TextInput";
import LabelTitle from "../Displays/LabelTitle";
import { useOutletContext } from "react-router-dom";
import { GlobalContextProps } from "../GlobalContext/GlobalContext";
import { useEffect } from "react";

interface PvSetupFormProps {
  form: UseFormReturn<PvSetupFormValues, any, undefined>;
}

const PvSetupForm = ({ form }: PvSetupFormProps) => {
  const { t } = useTranslation();
  const { setAnimations } = useOutletContext<GlobalContextProps>();
  const watchedPvInstalled = form.watch("pvInstalled");
  const performancePlaceholder = t("page.pvSetup.placeholder.performance");
  const postalCodePlaceholder = t("page.pvSetup.placeholder.postalCode");

  useEffect(() => {
    watchedPvInstalled === PvSetupTypes.JA
      ? setAnimations((prev) => ({ ...prev, Sun: true, Panels: true }))
      : setAnimations((prev) => ({ ...prev, Sun: false, Panels: false }));
  }, [watchedPvInstalled, form, setAnimations]);

  return (
    <Stack gap={6}>
      <RadioGroupInput control={form.control} name="pvInstalled">
        <Stack gap={3}>
          <RadioInput
            value={PvSetupTypes.NEIN}
            label={t("page.pvSetup.no")}
            labelPlacement="start"
            sx={(theme) => ({
              borderRadius: 2,
              border: `1px solid ${theme.palette.grey[100]}`,
              p: 4,
              display: "flex",
              justifyContent: "space-between",
              m: 0,
            })}
          />

          <Stack
            sx={(theme) => ({
              borderRadius: 2,
              border:
                form.watch("pvInstalled") === PvSetupTypes.JA
                  ? `1px solid ${theme.palette.grey[100]}`
                  : "none",
            })}
          >
            <RadioInput
              value={PvSetupTypes.JA}
              label={t("page.pvSetup.yes")}
              labelPlacement="start"
              sx={(theme) => ({
                borderRadius: 2,
                border:
                  form.watch("pvInstalled") === PvSetupTypes.JA
                    ? "none"
                    : `1px solid ${theme.palette.grey[100]}`,
                p: 4,
                display: "flex",
                justifyContent: "space-between",
                m: 0,
              })}
            />

            {form.watch("pvInstalled") === PvSetupTypes.JA && (
              <Stack
                sx={{
                  px: 4,
                  pb: 4,
                }}
              >
                <TextInput
                  fullWidth
                  placeholder={performancePlaceholder}
                  InputProps={{
                    endAdornment: (
                      <LabelTitle
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                        fontWeight={600}
                        text="kWp"
                      />
                    ),
                  }}
                  control={form.control}
                  name="pvPowerMaxKwp"
                  labelText=""
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </RadioGroupInput>

      <TextInput
        fullWidth
        placeholder={postalCodePlaceholder}
        control={form.control}
        name="postalCode"
        labelText={t("page.pvSetup.postalCodeLabel")}
      />
    </Stack>
  );
};

export default PvSetupForm;
