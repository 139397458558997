import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import FormContainer from "src/components/Displays/FormContainer";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { ROUTES } from "src/consts/routes";
import { SESSION_KEYS, SITE_TECHNICAL_DATA } from "src/consts/session";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import PvSetupForm from "src/components/Forms/PvSetupForm";
import { pvSetupFormSchema } from "src/schema/pvSetupFormSchema";
import { GlobalContextProps } from "src/components/GlobalContext/GlobalContext";

export enum PvSetupTypes {
  JA = "true",
  NEIN = "false",
  BLANK = "",
}

export interface PvSetupFormValues {
  pvInstalled?: PvSetupTypes;
  pvPowerMaxKwp?: string;
  postalCode?: string;
}

const PvSetup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formTitle = t("page.pvSetup.formTitle");
  const { setAnimations } = useOutletContext<GlobalContextProps>();

  const [getSessionStorageItem, setSessionStorageItem] =
    useSessionStorage<SITE_TECHNICAL_DATA>(SESSION_KEYS.SITE_TECHNICAL_DATA);

  const form = useForm<PvSetupFormValues>({
    defaultValues: {
      pvInstalled:
        (getSessionStorageItem()?.pvSetup?.pvInstalled as PvSetupTypes) ||
        PvSetupTypes.BLANK,
      pvPowerMaxKwp: getSessionStorageItem()?.pvSetup?.pvPowerMaxKwp,
      postalCode: getSessionStorageItem()?.pvSetup?.postalCode,
    },
    mode: "onSubmit",
    resolver: yupResolver(pvSetupFormSchema),
  });

  const handleBackClick = () => {
    navigate(ROUTES.INTRODUCTION);
  };

  const onSubmit = (data: PvSetupFormValues) => {
    const sessionData = getSessionStorageItem();
    const newData = { ...data };
    if (newData.pvInstalled === PvSetupTypes.NEIN) {
      if (newData?.pvPowerMaxKwp) {
        delete newData.pvPowerMaxKwp;
      }
    }
    const updatedSessionData: SITE_TECHNICAL_DATA = {
      ...sessionData,
      pvSetup: newData,
    };
    setSessionStorageItem(updatedSessionData);
    setAnimations((prev) => ({ ...prev, continue: !prev.continue }));
    navigate(ROUTES.WALLBOX);
  };

  return (
    <FormContainer title={formTitle} onSubmit={form.handleSubmit(onSubmit)}>
      <Box sx={{ marginTop: 6, marginBottom: 3 }}>
        <PvSetupForm form={form} />
      </Box>
      <FormSubmitContainer onClick={handleBackClick} />
    </FormContainer>
  );
};

export default PvSetup;
