import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import FormContainer from "src/components/Displays/FormContainer";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { ROUTES } from "src/consts/routes";
import { SESSION_KEYS, SITE_TECHNICAL_DATA } from "src/consts/session";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { GlobalContextProps } from "src/components/GlobalContext/GlobalContext";
import BatterySetupForm from "src/components/Forms/BatterySetupForm";
import { batterySetupFormSchema } from "src/schema/batterySetupFormSchema";

export enum BatterySetupTypes {
  JA = "true",
  NEIN = "false",
  BLANK = "",
}

export interface BatterySetupFormValues {
  batteryInstalled?: BatterySetupTypes;
  batteryCapacity?: string;
}

const BatterySetup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formTitle = t("page.batterySetup.formTitle");
  const { setAnimations } = useOutletContext<GlobalContextProps>();

  const [getSessionStorageItem, setSessionStorageItem] =
    useSessionStorage<SITE_TECHNICAL_DATA>(SESSION_KEYS.SITE_TECHNICAL_DATA);

  const form = useForm<BatterySetupFormValues>({
    defaultValues: {
      batteryInstalled:
        (getSessionStorageItem()?.batterySetup
          ?.batteryInstalled as BatterySetupTypes) || BatterySetupTypes.BLANK,
      batteryCapacity: getSessionStorageItem()?.batterySetup?.batteryCapacity,
    },
    mode: "onSubmit",
    resolver: yupResolver(batterySetupFormSchema),
  });

  const handleBackClick = () => {
    navigate(ROUTES.WALLBOX);
  };

  const onSubmit = (data: BatterySetupFormValues) => {
    const sessionData = getSessionStorageItem();
    const newData = { ...data };
    if (newData.batteryInstalled === BatterySetupTypes.NEIN) {
      if (newData?.batteryCapacity) {
        delete newData.batteryCapacity;
      }
    }
    const updatedSessionData: SITE_TECHNICAL_DATA = {
      ...sessionData,
      batterySetup: newData,
    };
    setSessionStorageItem(updatedSessionData);
    setAnimations((prev) => ({ ...prev, continue: !prev.continue }));
    navigate(ROUTES.ANNUAL_GRID_SUPPLY);
  };

  return (
    <FormContainer title={formTitle} onSubmit={form.handleSubmit(onSubmit)}>
      <Box sx={{ marginTop: 6, marginBottom: 3 }}>
        <BatterySetupForm form={form} />
      </Box>
      <FormSubmitContainer onClick={handleBackClick} />
    </FormContainer>
  );
};

export default BatterySetup;
