import { Stack, useTheme } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { SmartMeter } from "src/assets";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import ProgressBarTitle from "src/components/Displays/ProgressBarTitle";

const SmartMeterModalContent = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Stack gap={4}>
      <Stack gap={4}>
        <SmartMeter />
        <PageSubTitle
          color={theme.palette.grey[400]}
          text={t("page.smartMeter.modal.imageDescription")}
        />
      </Stack>

      <ProgressBarTitle
        color={theme.palette.grey[500]}
        text={t("page.smartMeter.modal.text1")}
      />
      <ProgressBarTitle
        color={theme.palette.grey[500]}
        text={t("page.smartMeter.modal.point1")}
      />
      <ProgressBarTitle
        color={theme.palette.grey[500]}
        text={t("page.smartMeter.modal.point2")}
      />
      <ProgressBarTitle
        color={theme.palette.grey[500]}
        text={t("page.smartMeter.modal.point3")}
      />

      <ProgressBarTitle
        color={theme.palette.grey[500]}
        text={
          <Trans
            i18nKey={
              t("page.smartMeter.modal.text2") as "page.smartMeter.modal.text2"
            }
            t={t}
            components={{ bold: <strong /> }}
          />
        }
      />
    </Stack>
  );
};

export default SmartMeterModalContent;
