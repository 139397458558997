import { Box, Stack } from "@mui/material";
import RainbowFooter from "src/components/Footer/RainbowFooter";
import Header from "./Header/Header";
import Content from "./Content/Content";
import { useSearchParams } from "react-router-dom";
import { leadTransfer } from "src/api/apiClients";
import { useCallback, useEffect } from "react";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import {
  SESSION_KEYS,
  UI_FLOW_PREFERENCES,
  USER_PROFILE_DATA,
  SITE_TECHNICAL_DATA,
} from "src/consts/session";
import { PvSetupTypes } from "src/pages/PvSetup";
import { WallboxTypes } from "src/pages/Wallbox";
import { BatterySetupTypes } from "src/pages/BatterySetup";
import { SmartMeterTypes } from "src/pages/SmartMeter";
import { numberFormat } from "src/helpers/numberFormat";
import FooterLinks from "src/components/Footer/FooterLinks";

export const MOBILE_NUMBER_PREFIX = "+49";

const Introduction = () => {
  const [searchParams] = useSearchParams();
  const transferId = searchParams.get("tid") || "";
  const salesPartnerUuid = searchParams.get("spid") || "";

  const [, setTransferId] = useSessionStorage<string>(SESSION_KEYS.TRANSFER_ID);
  const [, setSiteCorrelationId] = useSessionStorage<string>(
    SESSION_KEYS.SITE_CORRELATION_ID
  );
  const [, setUiFlowPreferences] = useSessionStorage<UI_FLOW_PREFERENCES>(
    SESSION_KEYS.UI_FLOW_PREFERENCES
  );
  const [, setUserProfileData] = useSessionStorage<USER_PROFILE_DATA>(
    SESSION_KEYS.USER_PROFILE_DATA
  );
  const [, setSiteTechnicalData] = useSessionStorage<SITE_TECHNICAL_DATA>(
    SESSION_KEYS.SITE_TECHNICAL_DATA
  );

  const getLeadTransfer = useCallback(async () => {
    try {
      const response = await leadTransfer({ transferId, salesPartnerUuid });

      setTransferId(transferId);
      setSiteCorrelationId(response.siteCorrelationId);
      setUiFlowPreferences({
        language: response.uiFlowPreferences.language,
        callbackUrlSuccess: response.uiFlowPreferences.callbackUrlSuccess,
        callbackUrlFailure: response.uiFlowPreferences.callbackUrlFailure,
      });
      setUserProfileData({
        firstName: response.userProfileData.firstname,
        lastName: response.userProfileData.lastname,
        country: response.userProfileData.country,
        zipCode: response.userProfileData.zipcode,
        city: response.userProfileData.city,
        street: response.userProfileData.street,
        houseNumber: response.userProfileData.streetNumber,
        email: response.userProfileData.emailAddress,
        mobileNumber:
          response.userProfileData.mobilePhone ?? MOBILE_NUMBER_PREFIX,
        yearlyGridSupplyKwh: numberFormat(
          response.userProfileData.yearlyGridSupplyKwh
            ? response.userProfileData.yearlyGridSupplyKwh.toString()
            : ""
        ),
        confirmEmail: response.userProfileData.emailAddress,
      });
      setSiteTechnicalData({
        pvSetup: {
          pvInstalled: response.siteTechnicalData.pvInstalled
            ? PvSetupTypes.JA
            : PvSetupTypes.BLANK,

          pvPowerMaxKwp: numberFormat(
            response.siteTechnicalData.pvPowerMaxKwp
              ? response.siteTechnicalData.pvPowerMaxKwp.toString()
              : ""
          ),

          postalCode: response.userProfileData.zipcode,
        },

        wallboxInstalled: response.siteTechnicalData.wallboxInstalled
          ? WallboxTypes.JA
          : WallboxTypes.BLANK,

        batterySetup: {
          batteryInstalled: response.siteTechnicalData.batteryInstalled
            ? BatterySetupTypes.JA
            : BatterySetupTypes.BLANK,

          batteryCapacity: numberFormat(
            response.siteTechnicalData.batterySizeKwh
              ? response.siteTechnicalData.batterySizeKwh.toString()
              : ""
          ),
        },

        smartMeterInstalled: response.siteTechnicalData.smartMeterInstalled
          ? SmartMeterTypes.JA
          : SmartMeterTypes.BLANK,
        heatpumpInstalled:
          response.siteTechnicalData.heatpumpInstalled?.toString(),
        marketLocationId: response.siteTechnicalData.marketLocationId,
        meterNumber: response.siteTechnicalData.meterNumber,
      });
    } catch (e) {
      setSiteCorrelationId("not found");
    }
  }, [
    transferId,
    salesPartnerUuid,
    setTransferId,
    setSiteCorrelationId,
    setUiFlowPreferences,
    setUserProfileData,
    setSiteTechnicalData,
  ]);

  useEffect(() => {
    getLeadTransfer();
  }, [getLeadTransfer]);

  return (
    <Stack
      sx={{
        minHeight: "100vh",
      }}
    >
      <Stack
        alignItems="center"
        sx={(theme) => ({
          width: "100%",
          minHeight: "100vh",
          backgroundColor: theme.palette.app.oppositePrimary,
          px: 4,
        })}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "fit-content",
            maxWidth: "1279px",
          }}
        >
          <Header />
          <Box
            sx={{
              height: "36px",
            }}
          />
          <Content />
        </Stack>
        <Box
          sx={{
            height: "36px",
          }}
        />
      </Stack>

      <FooterLinks isIntroductionPage={true} />
      <RainbowFooter />
    </Stack>
  );
};

export default Introduction;
