import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import {
  Box,
  Checkbox,
  CheckboxProps,
  Stack,
  SvgIcon,
  useTheme,
} from "@mui/material";
import Text from "src/components/Displays/Text";
import InputLabel from "src/components/Displays/InputLabel";
import { useTranslation } from "react-i18next";

interface CheckboxInputProps<T extends FieldValues>
  extends UseControllerProps<T> {
  inputLabelText?: string;
  checkboxLabelText?: string;
  checkboxTextComponent?: React.ReactNode;
}

const CheckboxInput = <T extends FieldValues>({
  name,
  control,
  inputLabelText = "",
  checkboxLabelText,
  checkboxTextComponent,
  ...props
}: CheckboxInputProps<T> & CheckboxProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <Box>
          <Box height={4} />
          <InputLabel text={inputLabelText} />
          <Box height={4} />
          <Stack direction={"row"} alignItems={"flex-start"} gap={2}>
            <Checkbox
              {...props}
              {...field}
              id="checkbox"
              checked={field.value}
              icon={
                <SvgIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                  }}
                >
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 4.75C1 3.09315 2.34315 1.75 4 1.75H12C13.6569 1.75 15 3.09315 15 4.75V12.75C15 14.4069 13.6569 15.75 12 15.75H4C2.34315 15.75 1 14.4069 1 12.75V4.75Z"
                      fill="white"
                    />
                    <path
                      d="M1 4.75C1 3.09315 2.34315 1.75 4 1.75H12C13.6569 1.75 15 3.09315 15 4.75V12.75C15 14.4069 13.6569 15.75 12 15.75H4C2.34315 15.75 1 14.4069 1 12.75V4.75Z"
                      stroke="#D5D5D5"
                      strokeWidth="2"
                    />
                  </svg>
                </SvgIcon>
              }
              checkedIcon={
                <SvgIcon sx={{ width: "16px", height: "16px" }}>
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_955_347)">
                      <path
                        d="M0 4.75C0 2.54086 1.79086 0.75 4 0.75H12C14.2091 0.75 16 2.54086 16 4.75V12.75C16 14.9591 14.2091 16.75 12 16.75H4C1.79086 16.75 0 14.9591 0 12.75V4.75Z"
                        fill={theme.palette.radioButton.main}
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5303 5.21967C12.8232 5.51256 12.8232 5.98744 12.5303 6.28033L7.03033 11.7803C6.88968 11.921 6.69891 12 6.5 12C6.30109 12 6.11032 11.921 5.96967 11.7803L3.46967 9.28033C3.17678 8.98744 3.17678 8.51256 3.46967 8.21967C3.76256 7.92678 4.23744 7.92678 4.53033 8.21967L6.5 10.1893L11.4697 5.21967C11.7626 4.92678 12.2374 4.92678 12.5303 5.21967Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_955_347">
                        <path
                          d="M0 4.75C0 2.54086 1.79086 0.75 4 0.75H12C14.2091 0.75 16 2.54086 16 4.75V12.75C16 14.9591 14.2091 16.75 12 16.75H4C1.79086 16.75 0 14.9591 0 12.75V4.75Z"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </SvgIcon>
              }
              sx={{ padding: "0", mt: "4px" }}
            />
            {checkboxLabelText && <Text text={checkboxLabelText} />}
            {checkboxTextComponent}
          </Stack>
          {error?.message && (
            <Box>
              <Text color="red" text={t(error.message as any)} />
            </Box>
          )}
        </Box>
      )}
      name={name}
      control={control}
    />
  );
};

export default CheckboxInput;
