import { Box, Stack, useTheme } from "@mui/material";

import ProgressBarTitle from "../Displays/ProgressBarTitle";
import VerticalLinearProgress from "../ProgressBar/VerticalLinearProgressBar";
import { useLocation } from "react-router-dom";
import HorizontalLinearProgressBar from "../ProgressBar/HorizontalLinearProgressBar";
import PageTitle from "../Displays/PageTitle";
import { ROUTES } from "src/consts/routes";
import MobileTitle from "../Displays/MobileTitle";
import FormTitle from "../Displays/FormTitle";
import { ORDER_PATHS } from "src/consts/paths";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { Tariff } from "src/models/clientsModel";
import { SESSION_KEYS } from "src/consts/session";
import { useTranslation } from "react-i18next";
import TariffProviderIcon from "../TarrifProviderIcon/TariffProbiderIcon";
import { Info, InfoWhite } from "src/assets";
import { useToggle } from "src/hooks/useToggle";
import Modal from "../Modal/Modal";
import TariffProviderModalContent from "./TariffProviderModalContent";
import { useContext } from "react";
import { ThemeValues } from "../ThemeUsage/theme";
import { ThemeContext } from "../ThemeUsage/ThemeUsage";

const tariffCalculator = (pathname: string) => {
  switch (pathname) {
    case ROUTES.YOUR_NAME:
      return 10;
    case ROUTES.CONTACT_INFORMATION:
      return 25;
    case ROUTES.ADDRESS:
      return 39;
    case ROUTES.TECHNICAL_DETAILS:
      return 54;
    case ROUTES.CONTRACT_CHANGE:
      return 70;
    case ROUTES.CURRENT_SUPPLIER:
      return 70;
    case ROUTES.DATE_OF_CONTRACT_START:
      return 70;
    case ROUTES.PAYMENT_DETAILS:
      return 85;
    case ROUTES.CONTRACT_SUMMARY:
      return 100;
    case ROUTES.CONCLUSION:
      return 100;
    default:
      return 0;
  }
};

const OrderTariffProgressBar = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [isOverlayOpen, toggleIsOverlayOpen] = useToggle(false);
  const [getTarrifs] = useSessionStorage<Tariff[]>(SESSION_KEYS.TARIFFS);

  const themeName = useContext(ThemeContext);
  const getLogo = () => {
    switch (themeName) {
      case ThemeValues.default:
        return <InfoWhite />;
      case ThemeValues.SMA:
        return <Info />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Stack
        gap="13px"
        sx={{
          display: { xs: "none", sm: "flex" },
        }}
      >
        <PageTitle
          opacity={ORDER_PATHS.includes(pathname) ? 1 : 0.32}
          text={t("globalContext.orderTariff.title")}
          color={
            ORDER_PATHS.includes(pathname)
              ? theme.palette.progressBar.activeLinks
              : theme.palette.progressBar.inactiveLinks
          }
        />
        {ORDER_PATHS.includes(pathname) && (
          <>
            <Box
              sx={{
                height: 270,
                display: { xs: "none", sm: "flex" },
                gap: 3,
              }}
            >
              <VerticalLinearProgress
                variant="determinate"
                value={100 - tariffCalculator(pathname)}
              />
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <ProgressBarTitle
                  color={
                    pathname === ROUTES.YOUR_NAME
                      ? theme.palette.progressBar.activeLinks
                      : theme.palette.progressBar.inactiveLinks
                  }
                  text={t("globalContext.orderTariff.name")}
                />
                <ProgressBarTitle
                  color={
                    pathname === ROUTES.CONTACT_INFORMATION
                      ? theme.palette.progressBar.activeLinks
                      : theme.palette.progressBar.inactiveLinks
                  }
                  text={t("globalContext.orderTariff.contactInformation")}
                />
                <ProgressBarTitle
                  color={
                    pathname === ROUTES.ADDRESS
                      ? theme.palette.progressBar.activeLinks
                      : theme.palette.progressBar.inactiveLinks
                  }
                  text={t("globalContext.orderTariff.address")}
                />
                <ProgressBarTitle
                  color={
                    pathname === ROUTES.TECHNICAL_DETAILS
                      ? theme.palette.progressBar.activeLinks
                      : theme.palette.progressBar.inactiveLinks
                  }
                  text={t("globalContext.orderTariff.technicalDetails")}
                />

                <ProgressBarTitle
                  color={
                    pathname === ROUTES.CONTRACT_CHANGE ||
                    pathname === ROUTES.CURRENT_SUPPLIER ||
                    pathname === ROUTES.DATE_OF_CONTRACT_START
                      ? theme.palette.progressBar.activeLinks
                      : theme.palette.progressBar.inactiveLinks
                  }
                  text={t("globalContext.orderTariff.currentSupplier")}
                />

                <ProgressBarTitle
                  color={
                    pathname === ROUTES.PAYMENT_DETAILS
                      ? theme.palette.progressBar.activeLinks
                      : theme.palette.progressBar.inactiveLinks
                  }
                  text={t("globalContext.orderTariff.paymentDetails")}
                />

                <ProgressBarTitle
                  color={
                    pathname === ROUTES.CONTRACT_SUMMARY
                      ? theme.palette.progressBar.activeLinks
                      : theme.palette.progressBar.inactiveLinks
                  }
                  text={t("globalContext.orderTariff.complete")}
                />
              </Stack>
            </Box>
          </>
        )}
      </Stack>

      {ORDER_PATHS.includes(pathname) &&
        pathname !== ROUTES.CONTRACT_SUMMARY &&
        pathname !== ROUTES.CONCLUSION && (
          <Stack
            sx={{
              display: { xs: "default", sm: "none" },
              px: 4,
              pt: 10,
              pb: 4,
              gap: "4px",
            }}
          >
            <MobileTitle
              text={t("globalContext.order")}
              color={theme.palette.calendar.main}
            />

            <Box
              sx={{
                display: "flex",
              }}
            >
              <FormTitle
                text={t("globalContext.tariffDisplayName", {
                  tariffDisplayName: getTarrifs()?.[0]?.tariffDisplayName ?? "",
                })}
                color={theme.palette.progressBar.primary}
              />

              <Stack direction={"row"} alignItems={"center"}>
                <TariffProviderIcon
                  tariffProviderDisplayName={
                    getTarrifs()?.[0]?.tariffProviderDisplayName ?? ""
                  }
                />

                <Stack direction="row" alignItems={"center"}>
                  <Stack
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={toggleIsOverlayOpen}
                  >
                    {getLogo()}
                  </Stack>
                </Stack>
              </Stack>
            </Box>

            <HorizontalLinearProgressBar
              variant="determinate"
              value={tariffCalculator(pathname)}
            />

            <Modal
              isOpen={isOverlayOpen}
              onClose={toggleIsOverlayOpen}
              onSubmit={toggleIsOverlayOpen}
            >
              <TariffProviderModalContent
                tariffProviderDisplayName={
                  getTarrifs()?.[0]?.tariffProviderDisplayName ?? ""
                }
              />
            </Modal>
          </Stack>
        )}
    </>
  );
};

export default OrderTariffProgressBar;
