import { Stack, useTheme } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ProgressBarTitle from "src/components/Displays/ProgressBarTitle";

const DetailsModalContent = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack gap={4}>
      <ProgressBarTitle
        color={theme.palette.grey[500]}
        text={t("page.tariff.electricity.detailsModalDescription1")}
      />

      <ProgressBarTitle
        fontWeight="bold"
        color={theme.palette.grey[500]}
        text={t("page.tariff.electricity.detailsModalDescription2")}
      />

      <Stack direction={"row"} gap={2}>
        <Stack color={theme.palette.grey[500]}>•</Stack>
        <Stack>
          <ProgressBarTitle
            color={theme.palette.grey[500]}
            text={t("page.tariff.electricity.detailsModalDescription2.1")}
          />
        </Stack>
      </Stack>

      <Stack direction={"row"} gap={2}>
        <Stack color={theme.palette.grey[500]}>•</Stack>
        <Stack>
          <ProgressBarTitle
            color={theme.palette.grey[500]}
            text={t("page.tariff.electricity.detailsModalDescription2.2")}
          />
        </Stack>
      </Stack>

      <Stack direction={"row"} gap={2}>
        <Stack color={theme.palette.grey[500]}>•</Stack>
        <Stack>
          <ProgressBarTitle
            color={theme.palette.grey[500]}
            text={t("page.tariff.electricity.detailsModalDescription2.3")}
          />
        </Stack>
      </Stack>

      <ProgressBarTitle
        fontWeight="bold"
        color={theme.palette.grey[500]}
        text={t("page.tariff.electricity.detailsModalDescription3")}
      />

      <ProgressBarTitle
        color={theme.palette.grey[500]}
        text={t("page.tariff.electricity.detailsModalDescription3.1")}
      />

      <ProgressBarTitle
        color={theme.palette.grey[500]}
        text={
          <Trans
            i18nKey={
              t(
                "page.tariff.electricity.detailsModalDescription3.2"
              ) as "page.tariff.electricity.detailsModalDescription3.2"
            }
            t={t}
            components={{
              linkTag: (
                <Link
                  to="https://eex.com/en/market-data/power/futures"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        }
      />
      <ProgressBarTitle
        fontWeight="bold"
        color={theme.palette.grey[500]}
        text={t("page.tariff.electricity.detailsModalDescription4")}
      />

      <Stack gap={2}>
        <Stack direction={"row"} gap={2}>
          <Stack color={theme.palette.grey[500]}>•</Stack>
          <Stack>
            <ProgressBarTitle
              color={theme.palette.grey[500]}
              text={t("page.tariff.electricity.detailsModalDescription5")}
            />
          </Stack>
        </Stack>

        <Stack direction={"row"} gap={2}>
          <Stack color={theme.palette.grey[500]}>•</Stack>
          <Stack>
            <ProgressBarTitle
              color={theme.palette.grey[500]}
              text={t("page.tariff.electricity.detailsModalDescription6")}
            />
          </Stack>
        </Stack>

        <Stack direction={"row"} gap={2}>
          <Stack color={theme.palette.grey[500]}>•</Stack>
          <Stack>
            <ProgressBarTitle
              color={theme.palette.grey[500]}
              text={t("page.tariff.electricity.detailsModalDescription7")}
            />
          </Stack>
        </Stack>

        <Stack direction={"row"} gap={2}>
          <Stack color={theme.palette.grey[500]}>•</Stack>
          <Stack>
            <ProgressBarTitle
              color={theme.palette.grey[500]}
              text={t("page.tariff.electricity.detailsModalDescription8")}
            />
          </Stack>
        </Stack>

        <Stack direction={"row"} gap={2}>
          <Stack color={theme.palette.grey[500]}>•</Stack>
          <Stack>
            <ProgressBarTitle
              color={theme.palette.grey[500]}
              text={t("page.tariff.electricity.detailsModalDescription9")}
            />
          </Stack>
        </Stack>

        <Stack direction={"row"} gap={2}>
          <Stack color={theme.palette.grey[500]}>•</Stack>
          <Stack>
            <ProgressBarTitle
              color={theme.palette.grey[500]}
              text={t("page.tariff.electricity.detailsModalDescription9.1")}
            />
          </Stack>
        </Stack>
      </Stack>

      <ProgressBarTitle
        color={theme.palette.grey[500]}
        text={t("page.tariff.electricity.detailsModalDescription10")}
      />
    </Stack>
  );
};

export default DetailsModalContent;
