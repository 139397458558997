import { Stack } from "@mui/material";
import { toDate } from "date-fns";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FormContainer from "src/components/Displays/FormContainer";
import LabelTitle from "src/components/Displays/LabelTitle";
import ContractStartDateForm from "src/components/Forms/ContractStartDateForm";

import Page from "src/components/Layout/Page";
import FormSubmitContainer from "src/components/Shared/FormSubmitContainer";
import { ROUTES } from "src/consts/routes";
import { SITE_TECHNICAL_DATA, SESSION_KEYS } from "src/consts/session";
import { useSessionStorage } from "src/hooks/useSessionStorage";

export interface ContractStartDateFormValues {
  contractStartDate: Date;
}

const ContractStartDate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageTitle = t("page.contractChange.pageTitle");
  const pageSubTitle = t("page.contractChange.pageSubTitle");
  const formTitle = t("page.contractChange.formTitle");
  const labelTitle = t("page.contractChange.datePicker.infoTitle");
  const labelText = t("page.contractChange.datePicker.infoText");

  const [getSessionStorageItem, setSessionStorageItem] =
    useSessionStorage<SITE_TECHNICAL_DATA>(SESSION_KEYS.SITE_TECHNICAL_DATA);

  const cancelContractType = getSessionStorageItem()?.cancelContractType;
  const cancelContractTypeAuto =
    getSessionStorageItem()?.cancelContractType === "auto";

  const { handleSubmit, control } = useForm<ContractStartDateFormValues>({
    defaultValues: {
      contractStartDate: toDate(
        getSessionStorageItem()?.contractStartDate || new Date()
      ),
    },
  });

  const handleBackClick = () => {
    navigate(ROUTES.CURRENT_SUPPLIER);
  };

  const onSubmit = (data: ContractStartDateFormValues) => {
    const sessionData = getSessionStorageItem();
    const updatedSessionData: SITE_TECHNICAL_DATA = {
      ...sessionData,
      contractStartDate: data?.contractStartDate?.toISOString(),
    };
    setSessionStorageItem(updatedSessionData);
    navigate(ROUTES.PAYMENT_DETAILS);
  };

  return (
    <Page pageTitle={pageTitle} pageSubTitle={pageSubTitle}>
      <FormContainer title={formTitle} onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2} sx={{ marginTop: 6, marginBottom: 3 }}>
          <ContractStartDateForm
            control={control}
            cancelContractType={cancelContractType}
          />
          {cancelContractTypeAuto && (
            <Stack
              maxWidth="327px"
              gap={1}
              padding={3}
              sx={(theme) => ({
                backgroundColor: theme.palette.grey[50],
                borderRadius: "8px",
              })}
            >
              <LabelTitle text={labelTitle} />
              <LabelTitle fontWeight="400" text={labelText} />
            </Stack>
          )}
        </Stack>
        <FormSubmitContainer onClick={handleBackClick} />
      </FormContainer>
    </Page>
  );
};

export default ContractStartDate;
