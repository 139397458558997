import PageTitle from "src/components/Displays/PageTitle";
import { useTranslation } from "react-i18next";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import { Box, Stack } from "@mui/material";
import PrimaryButton from "src/components/Shared/PrimaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FUNNEL_ORDER_RESPONSE, SESSION_KEYS } from "src/consts/session";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { MSG_IDS } from "src/consts/msgIds";
import { useEffect, useState } from "react";
import { ROUTES } from "src/consts/routes";

const ErrorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const msgId = searchParams.get("msgId") ?? "";
  const [subTitle, setSubTitle] = useState("");
  const [backBtnText, setBackBtnText] = useState("");
  const [backBtnPostcodeText, setBackBtnPostcodeText] = useState("");

  const [getFunnelOrderResponse] = useSessionStorage<FUNNEL_ORDER_RESPONSE>(
    SESSION_KEYS.FUNNEL_ORDER_RESPONSE
  );

  useEffect(() => {
    switch (msgId) {
      case MSG_IDS.badRequest:
        setSubTitle(t(`page.errorPage.${MSG_IDS.badRequest}`));
        setBackBtnText(t("page.errorPage.button"));
        break;
      case MSG_IDS.no_prices_available:
        setSubTitle(t(`page.errorPage.${MSG_IDS.no_prices_available}`));
        setBackBtnPostcodeText(t(`page.errorPage.goToPostcode`));
        break;
      default:
        setSubTitle(t(`page.errorPage.default`));
        setBackBtnText(t("page.errorPage.button"));
        break;
    }
  }, [t, msgId]);

  const handleBackClick = () => {
    let redirectUrl = getFunnelOrderResponse()?.redirectUrl;

    if (redirectUrl && redirectUrl !== "") {
      window.location.href = redirectUrl;
      return;
    }
    navigate(-1);
  };

  const handleBackPostcodeClick = () => {
    navigate(ROUTES.PV_SETUP);
  };

  return (
    <>
      <Stack gap={2} sx={{ mt: { xs: 8, sm: 0 } }}>
        <PageTitle text={t("page.errorPage.title")} />
        <>
          <Box height={8} />
          <PageSubTitle text={subTitle} />
        </>
        <Box height={24} />

        {backBtnText && (
          <Box>
            <PrimaryButton
              text={backBtnText}
              sx={{
                border: "1px solid white",
              }}
              onClick={handleBackClick}
            />
          </Box>
        )}

        {backBtnPostcodeText && (
          <Box>
            <PrimaryButton
              text={backBtnPostcodeText}
              sx={{
                textTransform: "none",
                border: "1px solid white",
              }}
              onClick={handleBackPostcodeClick}
            />
          </Box>
        )}
      </Stack>
    </>
  );
};

export default ErrorPage;
