export enum MSG_IDS {
  unknownTariff = "unknownTariff",
  missingAGB = "missingAGB",
  missingPaymentMethod = "missingPaymentMethod",
  missingBankDetails = "missingBankDetails",
  missingNewContractStartDate = "missingNewContractStartDate",
  errorUpdatingLead = "errorUpdatingLead",
  unknownEnergyProvider = "unknownEnergyProvider",
  badRequest = "badRequest",
  no_prices_available = "no_prices_available",
}
