import { useContext } from "react";
import { Logo } from "src/assets";
import { ThemeValues } from "../ThemeUsage/theme";
import { ThemeContext } from "../ThemeUsage/ThemeUsage";

interface BrandItemThemeChangerProps {
  color?: string;
}

const BrandItemThemeChanger = ({ color }: BrandItemThemeChangerProps) => {
  const themeName = useContext(ThemeContext);
  const getLogo = () => {
    switch (themeName) {
      case ThemeValues.SMA:
        return <></>;
      case ThemeValues.default:
        return <Logo color={"white"} />;
      default:
        return <></>;
    }
  };

  return <>{getLogo()}</>;
};
export default BrandItemThemeChanger;
