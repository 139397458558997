import { Box, Stack, Theme, useTheme } from "@mui/material";

import ProgressBarTitle from "../Displays/ProgressBarTitle";
import PageSubTitle from "../Displays/PageSubTitle";
import VerticalLinearProgress from "../ProgressBar/VerticalLinearProgressBar";
import { useLocation } from "react-router-dom";
import PageTitle from "../Displays/PageTitle";
import { TARIFF_AND_ORDER_PATHS, TARIFF_PATHS } from "src/consts/paths";
import { ROUTES } from "src/consts/routes";
import { useTranslation } from "react-i18next";

export const tariffCalculator = (pathname: string) => {
  switch (pathname) {
    case ROUTES.PV_SETUP:
      return 25;
    case ROUTES.WALLBOX:
      return 35;
    case ROUTES.BATTERY_SETUP:
      return 45;
    case ROUTES.ANNUAL_GRID_SUPPLY:
      return 55;
    case ROUTES.SMART_METER:
      return 67;
    case ROUTES.CALCULATED_SAVINGS:
      return 83;
    case ROUTES.TARIF:
      return 100;
    default:
      return 0;
  }
};

const TariffCalculatorProgressBar = () => {
  const theme = useTheme();

  const { pathname } = useLocation();
  const { t } = useTranslation();
  return (
    <Stack
      gap="13px"
      sx={{
        display: { xs: "none", sm: "flex" },
      }}
    >
      <PageTitle
        opacity={TARIFF_AND_ORDER_PATHS.includes(pathname) ? 1 : 0.32}
        text={t("globalContext.tariffCalculator.title")}
        color={
          TARIFF_AND_ORDER_PATHS.includes(pathname)
            ? theme.palette.progressBar.activeLinks
            : theme.palette.progressBar.inactiveLinks
        }
      />
      {TARIFF_PATHS.includes(pathname) && (
        <Box
          sx={{
            height: 245,
            display: { xs: "flex", sm: "default" },
            gap: 3,
          }}
        >
          <VerticalLinearProgress
            variant="determinate"
            value={100 - tariffCalculator(pathname)}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <ProgressBarTitle
              color={theme.palette.progressBar.primary}
              text={t("globalContext.tariffCalculator.declarations")}
            />
            <Box
              sx={(theme: Theme) => ({
                px: 3,
                display: "flex",
                flexDirection: "column",
                borderLeft: 1,
                borderColor: theme.palette.progressBar.border,
                gap: 2,
              })}
            >
              <PageSubTitle
                color={
                  pathname === ROUTES.PV_SETUP
                    ? theme.palette.progressBar.activeLinks
                    : theme.palette.progressBar.inactiveLinks
                }
                text={t("globalContext.tariffCalculator.pvSystem")}
              />
              <PageSubTitle
                color={
                  pathname === ROUTES.WALLBOX
                    ? theme.palette.progressBar.activeLinks
                    : theme.palette.progressBar.inactiveLinks
                }
                text={t("globalContext.tariffCalculator.wallbox")}
              />
              <PageSubTitle
                color={
                  pathname === ROUTES.BATTERY_SETUP
                    ? theme.palette.progressBar.activeLinks
                    : theme.palette.progressBar.inactiveLinks
                }
                text={t("globalContext.tariffCalculator.battery")}
              />
              <PageSubTitle
                color={
                  pathname === ROUTES.ANNUAL_GRID_SUPPLY
                    ? theme.palette.progressBar.activeLinks
                    : theme.palette.progressBar.inactiveLinks
                }
                text={t("globalContext.tariffCalculator.powerConsumption")}
              />
              <PageSubTitle
                color={
                  pathname === ROUTES.SMART_METER
                    ? theme.palette.progressBar.activeLinks
                    : theme.palette.progressBar.inactiveLinks
                }
                text={t("globalContext.tariffCalculator.smartMeter")}
              />
            </Box>
            <ProgressBarTitle
              color={
                pathname === ROUTES.CALCULATED_SAVINGS
                  ? theme.palette.progressBar.activeLinks
                  : theme.palette.progressBar.inactiveLinks
              }
              text={t("globalContext.tariffCalculator.savings")}
            />
            <ProgressBarTitle
              color={
                pathname === ROUTES.TARIF
                  ? theme.palette.progressBar.activeLinks
                  : theme.palette.progressBar.inactiveLinks
              }
              text={t("globalContext.tariffCalculator.tarif")}
            />
          </Box>
        </Box>
      )}
    </Stack>
  );
};

export default TariffCalculatorProgressBar;
