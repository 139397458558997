import { Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Text from "src/components/Displays/Text";

const ModalContent = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack gap={3}>
      <Stack direction={"row"} gap={2}>
        <Stack color={theme.palette.grey[500]}>
          <Text text={"•"} />
        </Stack>
        <Stack gap={2}>
          <Text text={t("page.calculatedSavings.modal.text1")} />
          <Stack ml={4} gap={2}>
            <Stack direction={"row"} gap={2}>
              <Stack color={theme.palette.grey[500]}>
                <ul style={{ listStyleType: "circle", margin: 0, padding: 0 }}>
                  <li>
                    <Text text={t("page.calculatedSavings.modal.subText1")} />
                  </li>
                </ul>
              </Stack>
            </Stack>

            <Stack color={theme.palette.grey[500]}>
              <ul style={{ listStyleType: "circle", margin: 0, padding: 0 }}>
                <li>
                  <Text text={t("page.calculatedSavings.modal.subText2")} />
                </li>
              </ul>
            </Stack>
          </Stack>
          <Text text={t("page.calculatedSavings.modal.subText3")} />
        </Stack>
      </Stack>

      <Stack direction={"row"} gap={2}>
        <Stack color={theme.palette.grey[500]}>
          <Text text={"•"} />
        </Stack>
        <Stack>
          <Text text={t("page.calculatedSavings.modal.text2")} />
        </Stack>
      </Stack>

      <Stack direction={"row"} gap={2}>
        <Stack color={theme.palette.grey[500]}>
          <Text text={"•"} />
        </Stack>
        <Stack>
          <Text text={t("page.calculatedSavings.modal.text3")} />
        </Stack>
      </Stack>

      <Stack direction={"row"} gap={2}>
        <Stack color={theme.palette.grey[500]}>
          <Text text={"•"} />
        </Stack>
        <Stack>
          <Text text={t("page.calculatedSavings.modal.text4")} />
        </Stack>
      </Stack>

      <Stack direction={"row"} gap={2}>
        <Stack color={theme.palette.grey[500]}>
          <Text text={"•"} />
        </Stack>
        <Stack>
          <Text text={t("page.calculatedSavings.modal.text5")} />
        </Stack>
      </Stack>

      <Stack direction={"row"} gap={2}>
        <Stack color={theme.palette.grey[500]}>
          <Text text={"•"} />
        </Stack>
        <Stack>
          <Text text={t("page.calculatedSavings.modal.text6")} />
        </Stack>
      </Stack>

      <Stack direction={"row"} gap={2}>
        <Stack color={theme.palette.grey[500]}>
          <Text text={"•"} />
        </Stack>
        <Stack>
          <Text text={t("page.calculatedSavings.modal.text7")} />
        </Stack>
      </Stack>

      <Stack direction={"row"} gap={2}>
        <Stack color={theme.palette.grey[500]}>
          <Text text={"•"} />
        </Stack>
        <Stack>
          <Text text={t("page.calculatedSavings.modal.text8")} />
        </Stack>
      </Stack>

      <Stack direction={"row"} gap={2}>
        <Stack color={theme.palette.grey[500]}>
          <Text text={"•"} />
        </Stack>
        <Stack>
          <Text text={t("page.calculatedSavings.modal.text9")} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ModalContent;
