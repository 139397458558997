import { ContractSummaryFormValues } from "src/pages/ContractSummary/ContractSummary";
import * as yup from "yup";

export const contractSummaryWithoutSmartMeterFormSchema: yup.ObjectSchema<ContractSummaryFormValues> =
  yup.object({
    smartMeter: yup
      .boolean()
      .required()
      .test("smartMeter-check", "page.contractSummary.error", function (value) {
        return value === true;
      }),
    wallet: yup
      .boolean()
      .required()
      .test("wallet-check", "page.contractSummary.error", function (value) {
        return value === true;
      }),
  });

export const contractSummaryWithSmartMeterFormSchema: yup.ObjectSchema<ContractSummaryFormValues> =
  yup.object({
    smartMeter: yup.boolean(),
    wallet: yup
      .boolean()
      .required()
      .test("wallet-check", "page.contractSummary.error", function (value) {
        return value === true;
      }),
  });
