import * as yup from "yup";
import { TechnicalDetailsFormValues } from "src/pages/TechnicalDetails";
import { REGEX } from "src/consts/regex";

export const technicalDetailsInputsFormSchema: yup.ObjectSchema<TechnicalDetailsFormValues> =
  yup.object({
    meterNumber: yup
      .string()
      .min(6, "page.technicalDetails.formErrors.meterNumberMinMax")
      .max(20, "page.technicalDetails.formErrors.meterNumberMinMax")
      .transform((value: string, originalValue: string) =>
        originalValue.trim() === "" ? null : value
      )
      .matches(
        REGEX.ALPHA_NUMERIC,
        "page.technicalDetails.formErrors.meterNumberInvalid"
      )
      .nullable(),

    marketLocationId: yup
      .string()
      .length(11, "page.technicalDetails.formErrors.marketLocationId")
      .transform((value: string, originalValue: string) =>
        originalValue.trim() === "" ? null : value
      )
      .matches(
        REGEX.MARKET_LOCATION_ID,
        "page.technicalDetails.formErrors.marketLocationId"
      )
      .nullable(),
  });
