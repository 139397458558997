import {
  AccordionProps,
  Box,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckCircle, QuestionMark } from "src/assets";
import ControlledAccordion from "src/components/Accordion/ControlledAccordion";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import Text from "src/components/Displays/Text";
import { Tariff } from "src/models/clientsModel";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { useState } from "react";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ChevronDown, ChevronUp } from "src/assets";
import Modal from "src/components/Modal/Modal";
import { useToggle } from "src/hooks/useToggle";
import DetailsModalContent from "./DetailsModalContent";
import ModalContent from "./ModalContent";
import { useSessionStorage } from "src/hooks/useSessionStorage";
import { SESSION_KEYS, USER_PROFILE_DATA } from "src/consts/session";
import PageTitle from "src/components/Displays/PageTitle";

interface ElectricityCostProps {
  expanded: string | boolean;
  handleChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  tariffsData?: Tariff[];
}

const ElectricityCost = ({
  expanded,
  handleChange,
  tariffsData,
}: ElectricityCostProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const currentMonth = format(new Date(), "LLLL", { locale: de });

  const [getUserSessionStorage] = useSessionStorage<USER_PROFILE_DATA>(
    SESSION_KEYS.USER_PROFILE_DATA
  );
  const yearlyGridSupplyKwh = getUserSessionStorage()?.yearlyGridSupplyKwh;

  const [fixExpandedDetails, setFixExpandedDetails] = useState<string | false>(
    ""
  );
  const [flexExpandedDetails, setFlexExpandedDetails] = useState<
    string | false
  >("");

  const [isOverlayOpenFix, toggleIsOverlayOpenFix] = useToggle(false);
  const [isOverlayOpenFlex, toggleIsOverlayOpenFlex] = useToggle(false);

  const handleChangeDetailsFix =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setFixExpandedDetails(newExpanded ? panel : false);
    };

  const handleChangeDetailsFlex =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setFlexExpandedDetails(newExpanded ? panel : false);
    };

  const savingsData = tariffsData && tariffsData[0];

  const monthlyCosts = savingsData && savingsData.fixTariff?.monthlyCosts;

  const yearlyCosts = savingsData && savingsData.fixTariff?.yearlyCosts;

  const fixTariffDisplayName =
    (savingsData && savingsData.fixTariff?.tariffDisplayName) ?? "";
  const fixWorkingPricePerKwh =
    (savingsData && savingsData.fixTariff?.workingPricePerKwh) ?? "";
  const fixBasePriceMonthly =
    (savingsData && savingsData.fixTariff?.basePriceMonthly) ?? "";

  const flexTariffDisplayName =
    (savingsData && savingsData.flexTariff?.tariffDisplayName) ?? "";
  const flexSupplyPriceFixedElementPerKwh =
    (savingsData && savingsData.flexTariff?.supplyPriceFixedElementPerKwh) ??
    "";
  const flexBasePriceMonthly =
    (savingsData && savingsData.flexTariff?.basePriceMonthly) ?? "";
  const flexWorkingPricePerKwh =
    (savingsData && savingsData.flexTariff?.workingPricePerKwh) ?? "";

  return (
    <>
      <ControlledAccordion
        name="electricityCost"
        expanded={expanded}
        handleChange={handleChange}
        title={t("page.tariff.electricity.title")}
        subtitleUnCollapsed={fixTariffDisplayName}
        backgroundColor={theme.palette.form.background}
        value={t("page.tariff.electricity.monthFormat", {
          monthlyCosts,
        })}
        subtitleCollapsed={t("page.tariff.electricity.subtitle")}
      >
        <Modal
          title={
            <PageTitle
              color={theme.palette.grey[500]}
              text={t("page.tariff.electricity.modalTitle1", {
                fixTariffDisplayName,
              })}
            />
          }
          isOpen={isOverlayOpenFix}
          onClose={toggleIsOverlayOpenFix}
          onSubmit={toggleIsOverlayOpenFix}
        >
          <DetailsModalContent />
        </Modal>

        <Modal
          title={
            <PageTitle
              color={theme.palette.grey[500]}
              text={t("page.tariff.electricity.modalTitle2", {
                flexTariffDisplayName,
              })}
            />
          }
          isOpen={isOverlayOpenFlex}
          onClose={toggleIsOverlayOpenFlex}
          onSubmit={toggleIsOverlayOpenFlex}
        >
          <ModalContent />
        </Modal>

        <Stack direction="row" justifyContent="space-between">
          <Text
            fontWeight={700}
            sx={{
              fontSize: "11px",
              lineHeight: "18px",
            }}
            text={t("page.tariff.electricity.year")}
          />
          <Text
            fontWeight={700}
            sx={{
              fontSize: "11px",
              lineHeight: "18px",
            }}
            text={t("page.tariff.electricity.yearFormat", {
              yearlyCosts,
            })}
          />
        </Stack>
        <Text
          fontWeight={700}
          sx={{
            fontSize: "11px",
            lineHeight: "18px",
          }}
          text={t("page.tariff.electricity.yearSubHeader", {
            fixTariffDisplayName,
          })}
        />
        <Stack
          gap={3}
          sx={{
            mt: 6,
          }}
        >
          <Stack direction="row" gap={2}>
            <Box>
              <CheckCircle />
            </Box>
            <PageSubTitle
              color={theme.palette.app.secondaryFont}
              text={t("page.tariff.electricity.dynamicTariff")}
            />
          </Stack>

          <Stack direction="row" gap={2}>
            <Box>
              <CheckCircle />
            </Box>

            <Stack>
              <Box>
                <PageSubTitle
                  fontWeight={"bold"}
                  color={theme.palette.app.secondaryFont}
                  text={fixTariffDisplayName}
                />
                <PageSubTitle
                  color={theme.palette.app.secondaryFont}
                  text={t("page.tariff.electricity.basicPrice", {
                    fixBasePriceMonthly,
                  })}
                />

                <PageSubTitle
                  color={theme.palette.app.secondaryFont}
                  text={t("page.tariff.electricity.workingPrice", {
                    fixWorkingPricePerKwh,
                    currentMonth,
                  })}
                />

                <Accordion
                  expanded={fixExpandedDetails === "panel1"}
                  onChange={handleChangeDetailsFix("panel1")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{
                      mt: 2,
                      padding: 0,
                      minHeight: "0px",
                      ".MuiAccordionSummary-content": { margin: 0 },
                    }}
                  >
                    <Stack direction={"row"} alignItems={"center"} padding={0}>
                      <PageSubTitle
                        color={theme.palette.app.secondaryFont}
                        text={t("page.tariff.electricity.detailsTitle")}
                      />
                      <Stack>
                        {fixExpandedDetails === "panel1" ? (
                          <ChevronDown />
                        ) : (
                          <ChevronUp />
                        )}
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      color={theme.palette.app.secondaryFont}
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "18px",
                      }}
                      mb={2}
                    >
                      {t("page.tariff.electricity.detailsDescription")}
                      <QuestionMark
                        onClick={toggleIsOverlayOpenFix}
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Stack>
          </Stack>

          <Stack direction="row" gap={2}>
            <Box>
              <CheckCircle />
            </Box>

            <Stack>
              <Box>
                <PageSubTitle
                  fontWeight={"bold"}
                  color={theme.palette.app.secondaryFont}
                  text={flexTariffDisplayName}
                />
                <PageSubTitle
                  color={theme.palette.app.secondaryFont}
                  text={t("page.tariff.electricity.flexBasePriceMonthly", {
                    flexBasePriceMonthly,
                  })}
                />
                <PageSubTitle
                  color={theme.palette.app.secondaryFont}
                  text={t("page.tariff.electricity.dynamicLabourPrice")}
                />
                <Stack>
                  <Stack direction={"row"} gap={2}>
                    <Stack>
                      <PageSubTitle
                        color={theme.palette.app.secondaryFont}
                        text={"•"}
                      />
                    </Stack>
                    <Stack>
                      <PageSubTitle
                        color={theme.palette.app.secondaryFont}
                        text={t(
                          "page.tariff.electricity.flexSupplyPriceFixedElementPerKwh",
                          {
                            flexSupplyPriceFixedElementPerKwh,
                          }
                        )}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction={"row"} gap={2}>
                    <Stack>
                      <PageSubTitle
                        color={theme.palette.app.secondaryFont}
                        text={"•"}
                      />
                    </Stack>
                    <Stack>
                      <PageSubTitle
                        color={theme.palette.app.secondaryFont}
                        text={t(
                          "page.tariff.electricity.flexSupplyPriceFixedElementPerKwhSubTitle"
                        )}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Accordion
                  expanded={flexExpandedDetails === "panel2"}
                  onChange={handleChangeDetailsFlex("panel2")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{
                      mt: 2,
                      padding: 0,
                      minHeight: "0px",
                      ".MuiAccordionSummary-content": { margin: 0 },
                    }}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <PageSubTitle
                        color={theme.palette.app.secondaryFont}
                        text={t("page.tariff.electricity.detailsTitle")}
                      />
                      <Stack>
                        {flexExpandedDetails === "panel2" ? (
                          <ChevronDown />
                        ) : (
                          <ChevronUp />
                        )}
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      color={theme.palette.app.secondaryFont}
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "18px",
                      }}
                      mb={2}
                    >
                      {t("page.tariff.electricity.flexDetailsDescription", {
                        flexWorkingPricePerKwh,
                      })}
                      <QuestionMark
                        onClick={toggleIsOverlayOpenFlex}
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Stack>
          </Stack>

          <Stack direction="row" gap={2}>
            <Box>
              <CheckCircle />
            </Box>
            <PageSubTitle
              color={theme.palette.app.secondaryFont}
              text={t("page.tariff.electricity.annualConsumptionText", {
                yearlyGridSupplyKwh,
              })}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <Box>
              <CheckCircle />
            </Box>
            <PageSubTitle
              color={theme.palette.app.secondaryFont}
              text={t("page.tariff.electricity.priceGuarantee")}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <Box>
              <CheckCircle />
            </Box>
            <PageSubTitle
              color={theme.palette.app.secondaryFont}
              text={t("page.tariff.electricity.contractTerm")}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <Box>
              <CheckCircle />
            </Box>
            <PageSubTitle
              color={theme.palette.app.secondaryFont}
              text={t("page.tariff.electricity.noticePeriod")}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <Box>
              <CheckCircle />
            </Box>
            <PageSubTitle
              color={theme.palette.app.secondaryFont}
              text={t("page.tariff.electricity.billingMonthly")}
            />
          </Stack>
        </Stack>
      </ControlledAccordion>
    </>
  );
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<Box sx={{ fontSize: "0.6rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
}));

export default ElectricityCost;
