import { useContext } from "react";
import { Box, Stack } from "@mui/material";
import { ThemeValues } from "../ThemeUsage/theme";
import { ThemeContext } from "../ThemeUsage/ThemeUsage";

const RainbowFooter = () => {
  const themeName = useContext(ThemeContext);
  const getFooter = () => {
    switch (themeName) {
      case ThemeValues.SMA:
        return <></>;
      default:
        return (
          <Stack
            sx={{
              mt: "auto",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FDA100",
                width: "100%",
                height: "16px",
              }}
            />
            <Box
              sx={{
                backgroundColor: "#F2251C",
                width: "100%",
                height: "16px",
              }}
            />
            <Box
              sx={{
                backgroundColor: "#6D379C",
                width: "100%",
                height: "16px",
              }}
            />
            <Box
              sx={{
                backgroundColor: "#004F43",
                width: "100%",
                height: "16px",
              }}
            />
          </Stack>
        );
    }
  };

  return <>{getFooter()}</>;
};

export default RainbowFooter;
