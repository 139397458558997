import { UseFormReturn } from "react-hook-form";
import RadioGroupInput from "src/components/Inputs/RadioGroupInput";
import RadioInput from "src/components/Inputs/RadioInput";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import TextInput from "../Inputs/TextInput";
import LabelTitle from "../Displays/LabelTitle";
import { useOutletContext } from "react-router-dom";
import { GlobalContextProps } from "../GlobalContext/GlobalContext";
import { useEffect } from "react";
import {
  BatterySetupFormValues,
  BatterySetupTypes,
} from "src/pages/BatterySetup";

interface BatterySetupFormProps {
  form: UseFormReturn<BatterySetupFormValues, any, undefined>;
}

const BatterySetupForm = ({ form }: BatterySetupFormProps) => {
  const { t } = useTranslation();
  const { setAnimations } = useOutletContext<GlobalContextProps>();
  const performancePlaceholder = t(
    "page.batterySetup.placeholder.batteryCapacity"
  );
  const watchedPvInstalled = form.watch("batteryInstalled");

  useEffect(() => {
    watchedPvInstalled === BatterySetupTypes.JA
      ? setAnimations((prev) => ({ ...prev, Battery: true }))
      : setAnimations((prev) => ({ ...prev, Battery: false }));
  }, [watchedPvInstalled, form, setAnimations]);

  return (
    <RadioGroupInput control={form.control} name="batteryInstalled">
      <Stack gap={3}>
        <RadioInput
          value={BatterySetupTypes.NEIN}
          label={t("page.batterySetup.no")}
          labelPlacement="start"
          sx={(theme) => ({
            borderRadius: 2,
            border: `1px solid ${theme.palette.grey[100]}`,
            p: 4,
            display: "flex",
            justifyContent: "space-between",
            m: 0,
          })}
        />
        <Stack
          sx={(theme) => ({
            borderRadius: 2,
            border:
              form.watch("batteryInstalled") === BatterySetupTypes.JA
                ? `1px solid ${theme.palette.grey[100]}`
                : "none",
          })}
        >
          <RadioInput
            value={BatterySetupTypes.JA}
            label={t("page.batterySetup.yes")}
            labelPlacement="start"
            sx={(theme) => ({
              borderRadius: 2,
              border:
                form.watch("batteryInstalled") === BatterySetupTypes.JA
                  ? "none"
                  : `1px solid ${theme.palette.grey[100]}`,
              p: 4,
              display: "flex",
              justifyContent: "space-between",
              m: 0,
            })}
          />
          {form.watch("batteryInstalled") === BatterySetupTypes.JA && (
            <Stack
              gap={2}
              sx={{
                px: 4,
                pb: 4,
              }}
            >
              <TextInput
                fullWidth
                placeholder={performancePlaceholder}
                InputProps={{
                  endAdornment: (
                    <LabelTitle
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                      fontWeight={600}
                      text={t(
                        "page.batterySetup.placeholder.batteryCapacityEndAdornment"
                      )}
                    />
                  ),
                }}
                control={form.control}
                name="batteryCapacity"
                labelText=""
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </RadioGroupInput>
  );
};

export default BatterySetupForm;
