import { createTheme } from "@mui/material";
import isonType from "src/fonts/isonType-Regular.woff2";
import { ScreenSizes } from "./common";

enum COLORS {
  primary = "#FFFFFF",
  secondary = "#000000",
  white = "#FFFFFF",
  yellow = "#FDA100",
  lila50 = "#DDB1FF",
  lila100 = "#6D379C",
  red = "#F2251C",
  green = "#004F43",
  green50 = "#009A22",
  green100 = "#C6FFB2",
  grey50 = "#E7EEF0",
  grey100 = "#D5D5D5",
  grey200 = "#ACACAC",
  grey300 = "#4E4843",
  grey400 = "#222222",
  grey500 = "#000000",
  grey600 = "#848F9F",
  black = "#000",
  error = "#BD002A",
  background = "#000000",
  discoColor = "#ea6a0f",
  discoColor2 = "#ca0f4e",
  discoColorAccent = "#e01a1d",
  progressBar = "#FDA100",
  mainFont = "#FFFFFF",
  radioButton = "#0075CF",
  tariffProviderModalIcon = "#FFFFFF",
}

export const discoTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
    },
    white: {
      main: COLORS.white,
    },
    yellow: {
      main: COLORS.yellow,
    },
    lila: {
      50: COLORS.lila50,
      100: COLORS.lila100,
    },
    red: {
      main: COLORS.red,
    },
    green: {
      main: COLORS.green,
      50: COLORS.green50,
      100: COLORS.green100,
    },
    grey: {
      50: COLORS.grey50,
      100: COLORS.grey100,
      200: COLORS.grey200,
      300: COLORS.grey300,
      400: COLORS.grey400,
      500: COLORS.grey500,
      600: COLORS.grey600,
    },
    error: {
      main: COLORS.error,
    },
    background: {
      default: COLORS.background,
    },
    mainFont: {
      primary: COLORS.mainFont,
    },
    progressBar: {
      primary: COLORS.progressBar,
      background: COLORS.grey400,
      activeLinks: COLORS.progressBar,
      inactiveLinks: COLORS.black,
      border: COLORS.grey300,
    },
    primaryButton: {
      text: COLORS.white,
      background: COLORS.discoColor,
    },
    secondaryButton: {
      text: COLORS.white,
      background: COLORS.discoColor2,
    },
    tabActive: {
      text: COLORS.white,
      background: COLORS.grey100,
    },
    tabInactive: {
      text: COLORS.white,
      background: COLORS.grey200,
    },
    calendar: {
      main: COLORS.grey200,
    },
    tariffProviderModalIcon: {
      main: COLORS.tariffProviderModalIcon,
    },
    outlineButton: {
      text: COLORS.black,
    },
    radioButton: {
      main: COLORS.radioButton,
    },
    form: {
      background: COLORS.white,
    },
    app: {
      primary: COLORS.white,
      primaryFont: COLORS.black,
      secondary: COLORS.black,
      secondaryFont: COLORS.black,
      oppositePrimary: COLORS.white,
      contrast: COLORS.white,
      accent: COLORS.discoColorAccent,
    },
  },

  spacing: 4,

  breakpoints: {
    values: {
      xs: ScreenSizes.XS,
      sm: ScreenSizes.SM,
      md: ScreenSizes.MD,
      lg: ScreenSizes.LG,
      xl: ScreenSizes.XL,
    },
  },

  typography: {
    fontFamily: "Inter",

    allVariants: {
      color: COLORS.primary,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "isonType",
          src: `url(${isonType}) format("woff2")`,
        },
      },
    },
  },
});

export default discoTheme;
