import { Route, Routes, useLocation } from "react-router-dom";

import YourName from "src/pages/YourName";
import ContactInformation from "src/pages/ContactInformation";
import Address from "src/pages/Address";
import ContractChange from "src/pages/ContractChange";
import { ROUTES } from "src/consts/routes";
import { Box, Container, Grid, Stack, useTheme } from "@mui/material";

import OrderTariffProgressBar from "./components/OrderTariffProgressBar/OrderTariffProgressBar";
import TariffCalculatorProgressBar from "./components/TariffCalculatorProgressBar/TariffCalculatorProgressBar";
import { TARIFF_AND_ORDER_PATHS } from "./consts/paths";
import ContractStartDate from "./pages/ContractStartDate";
import PaymentDetails from "./pages/PaymentDetails";
import TechnicalDetails from "./pages/TechnicalDetails";
import CurrentSupplier from "./pages/CurrentSupplier";
import SmartMeter from "./pages/SmartMeter";
import ContractSummary from "./pages/ContractSummary/ContractSummary";
import Conclusion from "./pages/Conclusion";
import Introduction from "./pages/Introduction/Introduction";
import RainbowFooter from "./components/Footer/RainbowFooter";
import Wallbox from "./pages/Wallbox";
import PvSetup from "./pages/PvSetup";
import AnnualGridSupply from "./pages/AnnualGridSupply";
import CalculatedSavings from "./pages/CalculatedSavings/CalculatedSavings";
import Tarif from "./pages/Tarif/Tarif";
import GlobalContext from "./components/GlobalContext/GlobalContext";
import BatterySetup from "./pages/BatterySetup";
import FooterLinks from "./components/Footer/FooterLinks";
import ErrorPage from "./pages/ErrorPage";
import BrandItemThemeChanger from "./components/BrandIconThemeChanger/BrandIconThemeChanger";

const App = () => {
  const { pathname } = useLocation();
  const theme = useTheme();
  if (pathname === ROUTES.INTRODUCTION) {
    return <Introduction />;
  }

  return (
    <Stack
      sx={(theme) => ({
        minHeight: "100vh",
        background: theme.palette.app.primary,
      })}
    >
      <Container
        sx={{
          marginTop: { xs: 0, sm: 12 },
          flexGrow: 1,
          justifyContent: "space-between",
          alignContent: "space-between",
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Stack gap="50px">
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <BrandItemThemeChanger color={theme.palette.app.primary} />
              </Box>
              {TARIFF_AND_ORDER_PATHS.includes(pathname) && (
                <>
                  <TariffCalculatorProgressBar />
                  <OrderTariffProgressBar />
                </>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={8} sx={{ paddingLeft: { sm: 5, md: 20 } }}>
            <Routes>
              <Route path="/" element={<GlobalContext />}>
                <Route path={ROUTES.PV_SETUP} element={<PvSetup />} />
                <Route path={ROUTES.BATTERY_SETUP} element={<BatterySetup />} />
                <Route path={ROUTES.WALLBOX} element={<Wallbox />} />
                <Route
                  path={ROUTES.ANNUAL_GRID_SUPPLY}
                  element={<AnnualGridSupply />}
                />
                <Route path={ROUTES.SMART_METER} element={<SmartMeter />} />
                <Route
                  path={ROUTES.CALCULATED_SAVINGS}
                  element={<CalculatedSavings />}
                />
                <Route path={ROUTES.TARIF} element={<Tarif />} />

                <Route path={ROUTES.YOUR_NAME} element={<YourName />} />
                <Route
                  path={ROUTES.CONTACT_INFORMATION}
                  element={<ContactInformation />}
                />
                <Route path={ROUTES.ADDRESS} element={<Address />} />
                <Route
                  path={ROUTES.TECHNICAL_DETAILS}
                  element={<TechnicalDetails />}
                />
                <Route
                  path={ROUTES.CONTRACT_CHANGE}
                  element={<ContractChange />}
                />
                <Route
                  path={ROUTES.CURRENT_SUPPLIER}
                  element={<CurrentSupplier />}
                />
                <Route
                  path={ROUTES.DATE_OF_CONTRACT_START}
                  element={<ContractStartDate />}
                />

                <Route
                  path={ROUTES.PAYMENT_DETAILS}
                  element={<PaymentDetails />}
                />
                <Route
                  path={ROUTES.CONTRACT_SUMMARY}
                  element={<ContractSummary />}
                />

                <Route path={ROUTES.CONCLUSION} element={<Conclusion />} />
                <Route path={ROUTES.ERROR} element={<ErrorPage />} />
              </Route>
            </Routes>
          </Grid>
        </Grid>
        <FooterLinks />
      </Container>
      <RainbowFooter />
    </Stack>
  );
};

export default App;
