import { Typography, styled, TypographyProps } from "@mui/material";
import { ReactNode } from "react";

interface ProgressBarTitleProps extends TypographyProps {
  color?: string;
  fontWeight?: string;
  text: string | ReactNode;
}

const ProgressBarTitle = ({
  text,
  color,
  fontWeight,
  ...props
}: ProgressBarTitleProps) => {
  const ProgressBarTitleStyles = styled(Typography)(({ theme }) => ({
    color: color || theme.palette.app.primaryFont,
    fontSize: "18px",
    lineHeight: "24px",
    fontStyle: "normal",
    fontFamily: "Inter",
    whiteSpace: "pre-wrap",
    fontWeight: fontWeight || "400",
  }));

  return <ProgressBarTitleStyles {...props}>{text}</ProgressBarTitleStyles>;
};

export default ProgressBarTitle;
