import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import TextInput from "../Inputs/TextInput";
import LabelTitle from "../Displays/LabelTitle";
import { Energy, MinusCircle, Persons, PlusCircle } from "src/assets";
import { AnnualGridSupplyFormValues } from "src/pages/AnnualGridSupply";

interface AnnualGridSupplyFormProps {
  form: UseFormReturn<AnnualGridSupplyFormValues, any, undefined>;
}

const calculatePowerConsumption = (
  persons: number,
  form: UseFormReturn<AnnualGridSupplyFormValues, any, undefined>
) => {
  switch (persons) {
    case 1:
      form.setValue("yearlyGridSupplyKwh", "1500");
      break;
    case 2:
      form.setValue("yearlyGridSupplyKwh", "2500");
      break;
    case 3:
      form.setValue("yearlyGridSupplyKwh", "3500");
      break;
    case 4:
      form.setValue("yearlyGridSupplyKwh", "4250");
      break;
    case 5:
      form.setValue("yearlyGridSupplyKwh", "5200");
      break;
    default:
      return "";
  }
  form.trigger("yearlyGridSupplyKwh");
};

const handleIncrement = (
  form: UseFormReturn<AnnualGridSupplyFormValues, any, undefined>
) => {
  const personsInHousehold = form.getValues("personsInHousehold");

  if (personsInHousehold === 5) return;
  const updatedPersonsInHousehold = personsInHousehold + 1;
  form.setValue("personsInHousehold", updatedPersonsInHousehold);
  calculatePowerConsumption(updatedPersonsInHousehold, form);
};

const handleDecrement = (
  form: UseFormReturn<AnnualGridSupplyFormValues, any, undefined>
) => {
  const personsInHousehold = form.getValues("personsInHousehold");

  if (personsInHousehold === 1) return;
  const updatedPersonsInHousehold = personsInHousehold - 1;

  form.setValue("personsInHousehold", updatedPersonsInHousehold);
  calculatePowerConsumption(updatedPersonsInHousehold, form);
};

const AnnualGridSupplyForm = ({ form }: AnnualGridSupplyFormProps) => {
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <TextInput
        fullWidth
        InputProps={{
          readOnly: true,
          startAdornment: <Persons />,
          endAdornment: (
            <Stack direction="row" gap={2} alignItems="center">
              <Stack
                direction="row"
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => handleDecrement(form)}
              >
                <MinusCircle />
              </Stack>
              <Stack
                direction="row"
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => handleIncrement(form)}
              >
                <PlusCircle />
              </Stack>
            </Stack>
          ),
        }}
        control={form.control}
        name="personsInHousehold"
        labelText={t("page.annualGridSupply.personsInHouseholdLable")}
      />

      <TextInput
        fullWidth
        placeholder={t("page.annualGridSupply.annualConsumptionLable")}
        InputProps={{
          startAdornment: <Energy />,
          endAdornment: (
            <LabelTitle
              sx={{
                whiteSpace: "nowrap",
              }}
              fontWeight={600}
              text={t("page.annualGridSupply.annualConsumptionUnitOfMeasure")}
            />
          ),
        }}
        control={form.control}
        name="yearlyGridSupplyKwh"
        labelText={t("page.annualGridSupply.annualConsumptionLable")}
      />
    </Stack>
  );
};

export default AnnualGridSupplyForm;
